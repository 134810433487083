@import "../../Global.less";

.SectionTitle {
    color: @grey-1;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;

    .responsive("ant-md", {
        font-size: 30px;
    });
}

.Feature {
    display: flex;
    flex: 1 1;
    flex-direction: column;

    & .max-wrap {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        align-self: center;
        width: 100%;
        max-width: 1200px;
        padding: 16px;

        .responsive("ant-md", {
            padding: 40px 32px;
        });

        & .CategorySelect {
            margin-bottom: 16px;

            .responsive("ant-md", {
                margin-bottom: 40px;
            });
        }

        & .load-more-btn {
            height: 40px;

            color: @grey-1;
            font-size: 16px;

            border: 1px solid @grey-5;
            border-radius: 4px;

            transition: border 300ms ease-out;

            .responsive("ant-md", {
                font-size: 14px;
            });

            &:hover,
            &:focus {
                color: @brand-blue;

                border: 1px solid @brand-blue;
            }

            & a {
                border: none;
            }

            & .anticon {
                margin-right: 8px;
            }
        }

        & .recent {
            margin-bottom: 32px;
            padding-top: 0;

            .responsive("ant-md", {
                padding-top: 32px;
                padding-bottom: 0;

                border-top: 1px solid @grey-5;
            });

            .responsive("ant-lg", {
                padding-bottom: 30px;
                border-bottom: 1px solid @grey-5;
            });

            & > * + * {
                margin-top: 16px;

                .responsive("ant-md", {
                    margin-top: 32px;
                });
            }
        }

        .app-banner {
            padding-bottom: 0;

            border: none;

            .responsive("ant-lg", {
                border-bottom: none;
                border-top: 1px solid @grey-5;
            });
        }

        & .past {
            & .SectionTitle {
                display: block;
                margin-bottom: 8px;

                .responsive("ant-md", {
                    margin-bottom: 16px;
                });
            }
        }

        & .timeline-title {
            color: @grey-2;
            font-weight: bold;
            font-size: 16px;
            line-height: 130%;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            .responsive("ant-md", {
                font-size: 20px;
                line-height: 120%;
            });
        }

        & .timeline {
            display: flex;
            flex-direction: column;
            padding-top: 16px;

            .responsive("ant-md", {
                padding-top: 32px;
            });

            & > * + * {
                margin-top: 16px;

                .responsive("ant-md", {
                    margin-top: 32px;
                });
            }
        }

        & .story-censored-stories {
            & .subtitle {
                color: @brand-red;
            }
        }

        & .story-best-of-the-web {
            & .subtitle {
                color: @grey-1;
            }
        }

        & .story {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            width: 288px;
            height: 100%;
            overflow: hidden;

            border: 1px solid @grey-5;
            border-radius: 4px;

            &:not(:last-of-type) {
                margin-right: 16px;
            }

            .responsive("ant-md", {
                width: inherit;
                flex-shrink: 1;
                margin-right: 0;
            });

            & .image {
                flex-shrink: 0;
                width: 100%;
                height: 144px;
                object-fit: cover;

                .responsive("ant-md", {
                    height: 164px;
                });
            }

            & .info {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 12px;

                .responsive("ant-md", {
                    padding: 16px;
                });

                & > * + * {
                    display: block;
                    padding-top: 4px;
                }

                & .subtitle {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 130%;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                }

                & .title {
                    color: @grey-1;
                    font-size: 14px;
                    line-height: 140%;
                    .max_lines(4);

                    .responsive("ant-md", {
                        font-size: 16px;
                    });
                }

                & .date {
                    margin-top: auto;

                    color: @grey-2;
                    font-size: 14px;
                    line-height: 140%;
                }
            }
        }

        & .ant-carousel .slick-initialized .slick-slide {
            height: 340px;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;