@import "../../Global.less";

.summary-container {
    padding: 30px 20px;

    background: @grey-7;

    .header {
        .responsive("ant-sm", {
            text-align: center;
        });
    }

    .title {
        color: @grey-1;

        font-weight: 700;
        font-size: 16px;

        .responsive("ant-sm", {
            font-size: 24px;
        });
    }

    .caption {
        font-weight: 400;
        font-size: 16px;
    }

    .summaries {
        display: flex;
        margin-top: 30px;

        overflow-x: auto;

        #left {
            order: 3;
        }

        #middle {
            order: 2;
        }

        #right {
            order: 1;
        }

        .responsive("ant-sm", {
            #left{order:1;}
            #middle{order:2;}
            #right{order:3;}
        });

        .responsive("ant-lg", {
            justify-content: center;
        });

        .content {
            width: 100%;
            min-width: calc(min(80vw, 300px));
            margin-right: 12px;

            .responsive("ant-sm", {
                min-width: calc(min(43vw, 300px));
                max-width: 332px;
            });
        }
    }
}

.StoryHeader {
    position: relative;

    width: 100%;

    overflow: hidden;

    .bg-container {
        position: absolute;

        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;

        background-color: rgba(8, 26, 51);
        transform: scale(1.1); // removes weird white border on safari
        filter: blur(6px);

        backdrop-filter: blur(40px);

        .bg-img {
            width: 100%;
            height: 100%;

            opacity: 0.55;
        }
    }

    .container {
        max-width: 1020px;
        padding: 40px 10px;

        .responsive("ant-md", {
            padding: 80px 10px 60px 10px;
        });

        .title {
            z-index: 1;

            margin-bottom: 20px;

            color: @white;
            font-weight: bold;
            font-size: 24px;
            line-height: 120%;
            text-align: center;

            .responsive("ant-md", {
                font-size: 36px;
                line-height: 110%;
                margin-bottom: 30px;
            });
        }

        .topic-title {
            z-index: 1;

            margin-left: 10px;

            color: @white;
            color: #fff;
            font-weight: 700;
            font-size: 16px;
            line-height: 130%;
            text-align: center;
        }

        .summaries {
            margin: 40px 0 0;
            .responsive("ant-md", {
                margin: 70px 0 0 0;
            });
        }

        .socials-and-info {
            display: flex;
            flex-direction: column-reverse;
            align-content: center;
            align-items: center;
            justify-content: center;

            margin: 0;

            & > * + * {
                margin-top: 20px;
            }

            .responsive("ant-md", {
                & > * + * {
                    margin-top: 0;
                    margin-left: 80px;

                }
                flex-direction: row;
            });

            .trending-container {
                z-index: 1;

                display: none;
                align-items: center;

                .responsive("ant-md", {
                    display: flex;
                });

                .trending-text {
                    margin-left: 10px;

                    color: @white;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 130%;

                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                }
            }

            .ShareExpanded {
                .ShareIcon {
                    margin-left: 15px;

                    background-color: @white;

                    &:first-child {
                        margin-left: 0;
                    }

                    svg {
                        color: @grey-1;
                    }
                }
            }

            .updated {
                z-index: 1;

                margin-top: 0;

                color: @white;
                font-weight: bold;
                font-size: 16px;
                line-height: 130%;

                letter-spacing: 0.05em;
                text-transform: uppercase;
            }
        }

        .ShareCompressed {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            .responsive("ant-lg", {
                justify-content: flex-end;
            });

            .ant-btn {
                border: none;

                &:hover {
                    background-color: @white;
                    border: none;
                }
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;