@import "../../Global.less";

.Tweet {
    display: flex;
    flex-direction: column;
    min-width: 255px;
    height: 100%;
    padding: 16px 16px 12px;

    text-align: left;

    background-color: @white;
    border: 1px solid #e5e5ea;
    border-radius: 4px;

    .responsive("<desktop", {
        scroll-snap-stop: always;
    });

    .responsive("ant-sm", {
        min-width: 250px;
        scroll-snap-align: center;
    });

    .responsive("ant-lg", {
        min-width: 230px;
    });

    .tweet-body {
        margin-top: 1em;
        margin-bottom: 0.5em;

        & > a {
            color: @grey-1;
            font-size: 16px;
            line-height: 1.4;
            text-decoration: none;
        }

        .tweet-body-text {
            margin-bottom: 0;
        }
    }

    .tweet-header {
        display: flex;
        align-items: center;
        min-width: 0;

        & > .tweet-author {
            display: flex;
            flex-direction: column;
            margin: 0 5px 0 8px;

            line-height: 1.2;

            .tweet-author-title {
                .max_lines(1);
                .no_wrap();

                margin-bottom: 0;
                margin-bottom: 0.3em;

                font-weight: bold;
                font-size: 16px;
            }

            .username {
                .max_lines(1);
                .no_wrap();

                color: @grey-2;
                font-size: 14px;
            }
        }

        & > svg {
            margin-left: auto;
        }

        & > img {
            margin-right: 5px;

            border-radius: 50%;
        }
    }

    .tweet-divider {
        margin: auto 0 4px !important;
    }

    .tweet-footer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .twitter-logo {
            position: relative;
            top: 5px;

            display: flex;
            align-items: center;

            & .anticon-twitter {
                margin-right: 8px;

                color: #1da1f2;
                font-size: 16px;
            }

            .text {
                color: @grey-2;
                font-weight: 700;
                font-size: 14px;
            }
        }

        .tweet-context {
            margin-top: auto;

            color: @grey-2;
            font-size: 14px;

            line-height: 100%;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;