.NewsSnapshots {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    border-radius: 4px;

    @media only screen and (min-width: 641px) and (max-width: 1260px) {
        padding: 0;
    }

    @media only screen and (max-width: 640px) {
        padding: 16px 0 0;
    }

    h3.ant-typography {
        color: #3b4355;
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
    }

    .NewsStory {
        max-width: 100%;

        .ShowMore {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }

    .NewsStory:first-of-type {
        margin-bottom: 16px;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;