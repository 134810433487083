@import "../../Global.less";

.SpangldCTA {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;

    background-color: #081a33;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    border-radius: 0;
    border-radius: @border-radius;

    h2 {
        line-height: 120% !important;
    }

    .summary {
        font-size: 16px;
        line-height: 140%;
    }

    .Branding {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 48px;
        padding: 5px 8px;

        color: #3b4355;
        font-weight: bold;
        font-size: 14px;
        font-family: "Source Sans Pro";
        font-style: normal;
        line-height: 120%;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        background: #f8f9fb;
        border: 1px solid #e1e5eb;
        border-radius: @border-radius;

        a {
            display: flex;
        }

        img {
            width: 16px;
            height: 16px;
            margin-right: 6px;
        }
    }

    .SpangldView {
        text-decoration: underline;

        cursor: pointer;
    }

    & > *:not(.Branding) {
        color: #fff;

        &:is(a) {
            margin-top: 8px;

            text-decoration: underline;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;