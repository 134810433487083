@import "../../Global.less";

.TopicsSidebarItem {
    display: flex;
    align-items: center;
    align-self: center;
    padding: 16px 0;

    & .ant-avatar {
        margin-right: 8px;
    }

    .title {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        font-weight: bold;
        font-size: 18px;
        line-height: 120%;

        .responsive("<desktop", {
            font-size: 16px;
            font-weight: normal;
            margin-right: 4px;

            .ant-tag {
                margin-right: 0px;
            }
        });

        & > div > a {
            color: #3b4355;

            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;