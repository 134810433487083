@import "../../Global.less";

.NewsStory {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 100%;
    height: fit-content;
    padding: @offset-xs @offset-xs calc(@offset-xs / 2) @offset-xs;
    overflow: hidden;

    background: @grey-7;
    border-radius: 0;

    .responsive("ant-md", {
        padding: 20px;
        overflow: unset;
        background: #f8f9fb;
    });

    /*  For <DesktopLayout />  */
    .responsive("ant-lg", {
        border-radius: @border-radius;
        border: 1px solid #e1e5eb;
    });

    & .ant-card-meta-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        color: #7d8799;
        font-weight: bold;
        font-size: 16px;
        font-family: "Source Sans Pro";
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }

    .ant-card-meta {
        margin: 0 !important;
    }

    .ant-card {
        background: inherit;

        .ant-card-body {
            display: flex;
            flex-direction: column;
            padding: 0;
        }

        & .ant-card-meta-title {
            position: relative;
        }
    }

    & .NewsMeta {
        display: flex;
        flex-direction: column;

        .responsive("ant-md", {
            display: unset;
        });
    }

    & .NewsStoryImage {
        width: 100%;
        height: 245px;

        border-radius: 2px;

        img {
            border-radius: 2px;
        }

        .responsive("ant-md", {
            width: 252px;
            height: 195px;

        });
    }

    .ant-card-meta-detail > div:not(:last-child) {
        margin-bottom: 10px;
    }

    .story-headline-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
        overflow: hidden;

        .responsive("ant-sm", {
            flex-direction: row;
            justify-content: space-between;
        });
    }

    .story-body {
        margin: 0 -@offset-xs;

        background: #f8f9fb;

        .responsive("ant-md", {
            margin: 12px 0 0 0;
            background: inherit;
        });

        .ant-card-body {
            padding: 12px 0 0;

            background: @grey-7 !important;

            .responsive("ant-md", {
                background-color: @white !important;
                padding: unset;
            });
        }

        .ScrollableArticles {
            background: @grey-7;
        }
    }

    .image-container {
        height: 210px;
        margin-top: 12px;

        .responsive("ant-sm", {
            width: 280px;
            height: 170px;
            margin: 0 30px 0 20px;
        });
    }

    .story-headline {
        display: flex;
        flex-direction: column;

        .responsive("ant-sm", {
            flex: 2;
        });

        a:hover {
            text-decoration: underline;
        }

        .story-title {
            margin-right: 8px;
            margin-bottom: 0;

            color: @grey-1;
            font-weight: bold;
            font-size: 24px;
            line-height: 125%;

            .responsive("ant-md", {
                margin-right: 24px;
                margin-bottom: 4px;
                font-size: 24px;
                line-height: 120%;
            });
        }

        .category-container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .category {
                color: @grey-2;

                font-weight: 700;
                font-size: 14px;
                letter-spacing: 5%;
            }
        }

        .updated-date-container {
            display: flex;
            justify-content: space-between;

            .responsive("ant-sm", {
                flex: 2;
            });

            .updated-date-text {
                margin-top: auto;

                color: #7d8799;
                font-size: 14px;
                line-height: 140%;
            }
        }
    }

    .NewsStorySpangld {
        width: 100%;
        margin: 0 0 12px;
        padding: 16px;

        background: #fff;
        border: 1px solid #e1e5eb;
        border-radius: 2px;
        box-shadow: none;
    }

    .ant-row {
        width: -webkit-fill-available;
        margin-bottom: 16px;
    }

    .top-three-or-all {
        display: flex;
        width: 100vw;
        height: 255px;
        padding-bottom: 1em;
        overflow-x: scroll;
        overflow-y: hidden;

        .responsive("ant-sm", {
            height: 320px;
        });

        // Add spacing between children
        & > * + * {
            margin-left: 12px;

            // Sets min width for every item except 1st and last which are the spacers
            &:not(:first-child):not(:last-child) {
                min-width: 320px;
            }
        }

        & > * + :nth-child(2) {
            margin-left: @offset-xs !important;
        }

        & > * + :last-child {
            margin-left: @offset-xs !important;
        }

        .responsive("ant-md", {
            width: auto;
            height: auto;
            padding-bottom: unset;
            overflow-x: unset;
            overflow-y: unset;

            // Add spacing between children
            & > * + * {
                margin-left: unset;
            }
        });

        .ant-row {
            max-width: -webkit-fill-available;
        }

        .article-spacer {
            background: transparent;
            border: 0;

            .responsive("ant-md", {
                padding: unset;
                background: unset;
                border: unset;
            });

            &.with-padding {
                border: 1px solid transparent;

                ::after {
                    content: "";
                }
            }
        }
    }

    // Ant Divider
    .new-story-divider {
        margin: 20px 0 8px;
        padding: 0 16px;

        border-color: #e1e5eb;
    }

    & .card-relative {
        position: relative;
    }

    & .ant-collapse {
        & .ant-collapse-item {
            display: flex;
            flex-direction: column-reverse;

            & .ant-collapse-header {
                display: flex;
                align-items: center;
                padding: 0 !important;

                color: @grey-2;
                font-size: 16px;
                line-height: 140%;

                .ant-collapse-arrow {
                    margin-right: 8px;

                    font-size: 16px;
                    line-height: 16px;
                    vertical-align: -2px;
                }

                & > span {
                    margin-right: 8px;

                    & svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            & .ant-collapse-content-box {
                margin-bottom: 16px;
                padding: 0 !important;
            }
        }
    }

    & .ContentCard {
        border-radius: 4px;

        .responsive("ant-md", {
            min-width: 0 !important;
        });
    }
}

.NewsStory.Video {
    padding-bottom: 16px;

    .responsive("ant-md", {
        padding-bottom: 20px;
    });

    & .ant-card-meta-avatar {
        margin: -16px -16px 16px;
        padding: 0;

        .responsive("ant-md", {
            margin: unset;
            padding-right: 16px;
        });
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;