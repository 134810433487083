@import "../../Global.less";

.ImageDetailsPage {
    width: 100%;
    height: 100%;
    overflow: hidden;

    background-color: @white;

    &.d-none {
        display: none;
    }

    .header {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 30px;

        .back-button {
            display: flex;
            align-items: center;
            padding: 0;

            border: none;

            &:hover {
                color: unset;
            }

            svg {
                margin-right: 20px;
            }

            .header-title {
                margin-bottom: 0;

                color: @grey-1;
                font-weight: 600;
                font-size: 20px;
                line-height: 100%;

                .responsive("ant-lg", {
                    font-size: 30px;
                });
            }
        }
    }

    .container {
        .responsive("ant-lg", {
            display: flex;
            justify-content: space-between;
        });

        .inner-container {
            display: flex;
            flex: 1;
            flex-direction: column;
        }
    }

    .image-block {
        display: flex;
        flex-direction: column;

        margin-bottom: 40px;

        .responsive("ant-lg", {
            margin-right: 40px;
        });

        .main-img {
            align-self: center;
            justify-self: center;
            width: 100%;
            height: auto;
            max-height: 50vh;
            margin-bottom: 30px;
            object-fit: contain;
        }

        .image-title {
            margin-bottom: 10px;

            color: @grey-1;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;

            .responsive("ant-lg", {
                margin-bottom: 16px;
                font-size: 24px;
                line-height: 120%;
            });
        }

        .copyright-container {
            margin-bottom: 10px;

            .responsive("ant-lg", {
                margin-bottom: 16px;
            });
        }

        .info-container {
            display: flex;
            align-items: center;

            .responsive("ant-lg", {
                margin-bottom: 16px;
            });
        }

        .info-text {
            margin-right: 12px;

            color: @grey-1;
            font-weight: 400;
            font-size: 16px;

            .responsive("ant-lg", {
                font-size: 20px;
            });
        }

        .info-link {
            display: inline-block;

            color: @grey-1;
            font-weight: 400;
            font-size: 16px;
            text-decoration-line: underline;

            .responsive("ant-lg", {
                font-size: 20px;
            });
        }

        .info-size {
            display: inline-block;
        }

        .save-button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;
            padding: 0;

            border: none;

            &:hover {
                color: unset;
            }

            span {
                color: @grey-1;

                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                text-decoration-line: underline;

                .responsive("ant-lg", {
                    font-size: 20px;
                });
            }
        }

        .bullet {
            margin: 0 12px;

            color: @grey-3;
        }
    }

    .related-block {
        .responsive("ant-lg", {
            margin-top: 2px;
            min-width: 210px;
        });

        .related-title {
            margin-bottom: 30px;

            color: @grey-1;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;

            .responsive("ant-lg", {
                font-size: 30px;
            });
        }

        .content-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            overflow-x: scroll;

            .responsive("ant-sm", {
                overflow-x: unset;
                flex-direction: row;
            });

            .responsive("ant-lg", {
                overflow-x: unset;
                flex-direction: column;
            });
        }

        .related-content-card {
            margin-bottom: 20px;

            .responsive("ant-sm", {
                margin-right: 20px;
            });

            .responsive("ant-lg", {
                max-width: 210px;
                margin-right: 0;
                margin-bottom: 20px;
            });

            .related-content-button {
                height: 100%;
                padding: 0;

                border: 0;

                box-shadow: none;
            }

            .related-img {
                width: 100%;
                min-width: 100px;
                height: auto;
                margin-bottom: 8px;
                object-fit: contain;

                .responsive("ant-sm", {
                    width: auto;
                    height: 200px;
                });

                .responsive("ant-lg", {
                    width: 100%;
                    height: auto;
                });
            }

            .related-img-title {
                color: @grey-1;
                font-weight: 400;
                font-size: 16px;
                line-height: 110%;
                white-space: normal;
                text-align: left;
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;