@import "../../Global.less";

& .Container.Products-header {
    margin-top: 24px;

    .responsive("ant-md", {
        margin-top: 56px;
    });

    & .page-title {
        margin-bottom: 24px;

        color: @brand-navy;
        font-weight: bold;
        font-size: 24px;
        font-family: "Source Sans Pro";
        font-style: normal;
        line-height: 120%;
        letter-spacing: 0.02em;
        text-align: center;
        text-transform: uppercase;

        .responsive("ant-md", {
            margin-bottom: 48px;

            font-size: 30px;
        });
    }
}

& .ShopUsaMenu-desktop {
    margin-bottom: 14px;
}

& .ShopUsaMenu-mobile {
    margin-bottom: 24px;
}

.Products {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    .responsive("ant-md", {
        margin-bottom: 90px;
    });

    .ScrollableArticles .desktop-carousel .ant-carousel .slick-arrow {
        top: 42%;
    }

    .ScrollableArticles .desktop-carousel .ant-carousel .slick-next {
        right: -10px;
    }

    & .content {
        & > *:not(:last-of-type) {
            padding-bottom: 24px;

            border-bottom: 1px solid @grey-6;

            .responsive("ant-md", {
                padding-bottom: 40px;
            });
        }

        & .banner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 272px;

            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../static/images/shop-usa/bike-and-flag.png");
            background-repeat: no-repeat;
            background-position: top center;
            background-size: cover;
            border-radius: 4px;

            .responsive("ant-md", {
                height: 560px;
            });

            & .title {
                margin-bottom: 12px;

                color: @white;
                font-weight: bold;
                font-size: 30px;
                line-height: 120%;
                text-align: center;

                .responsive("ant-md", {
                    margin-bottom: 8px;

                    font-size: 48px;
                    line-height: 60px;
                });
            }

            & .subtitle {
                color: @white;
                font-size: 16px;
                line-height: 140%;
                text-align: center;

                .responsive("ant-md", {
                    font-size: 20px;
                });
            }
        }

        & .small-categories {
            display: flex;
            justify-content: space-between;
            width: 100%;
            overflow-x: scroll;
            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none; /* Internet Explorer 10+ */

            &::-webkit-scrollbar {
                /* WebKit */
                display: none !important;
                width: 0;
                height: 0;
            }

            & .ProductCard {
                flex: 1;
            }

            & > * + * {
                margin-left: 24px;
            }
        }

        & .large-categories {
            display: flex;
            flex-wrap: wrap;

            & > * + * {
                margin-top: 24px;
            }

            .responsive("ant-md", {
                margin-top: -40px;
                margin-right: -40px;
            });

            & .ProductCard {
                flex: 1 0 100%;

                & .cover {
                    .responsive("not:ant-md", {
                        height: 220px;
                    });
                }

                .responsive("ant-md", {
                    flex: 1 0 calc(50% - 80px);
                    margin-top: 40px;
                    margin-right: 40px;
                    margin-bottom: 0;
                });
            }
        }

        & .popular {
            display: flex;
            flex-direction: column;

            & .popular-title {
                color: @brand-navy;
                font-weight: bold;
                font-size: 24px;
                line-height: 120%;
                text-align: center;

                .responsive("ant-md", {
                    font-size: 38px;
                });
            }

            & .popular-subtitle {
                margin: 0 auto;

                margin-bottom: 24px;

                color: @brand-navy;

                font-size: 16px;
                text-align: center;

                .responsive("ant-md", {
                    margin-bottom: 40px;
                });

                a {
                    color: @brand-navy;
                    text-decoration: underline;
                }
            }

            & .popular-products {
                display: flex;
                // overflow-x: scroll;
                scrollbar-width: none; /* Firefox */
                -ms-overflow-style: none; /* Internet Explorer 10+ */

                &::-webkit-scrollbar {
                    /* WebKit */
                    display: none !important;
                    width: 0;
                    height: 0;
                }

                & .ProductListing {
                    flex: 1;
                    width: 100%;
                    min-width: 280px;
                    max-width: 280px;
                    height: 100%;

                    & .cover {
                        height: 100%;
                        max-height: 280px;

                        img {
                            max-width: 100%;
                            height: auto;
                            max-height: 100%;
                            object-position: center center;
                        }
                    }

                    @media only screen and (min-width: 768px) and (max-width: 1000px) {
                        min-width: 0;
                        max-width: calc((100vw - (16px * 3)) / 2);

                        & .cover {
                            min-height: 350px;
                            max-height: calc((100vw - (16px * 3)) / 2);
                        }
                    }

                    @media only screen and (min-width: 1000px) {
                        min-width: 0;
                        max-width: 380px;

                        & .cover {
                            min-height: 380px;
                            max-height: 380px;
                        }
                    }

                    // keeps the last popular product box item half visible on different screen width - so users know it's scrollable
                    // @media only screen and (min-width: 530px) and (max-width: 900px) {
                    //     min-width: 35vw;

                    //     & .cover {
                    //         max-width: 35vw;
                    //         max-height: 35vw;
                    //     }
                    // }

                    // @media only screen and (min-width: 901px) and (max-width: 1350px) {
                    //     min-width: 25vw;

                    //     & .cover {
                    //         max-width: 25vw;
                    //         max-height: 25vw;
                    //     }
                    // }

                    // @media only screen and (min-width: 1351px) and (max-width: 1550px) {
                    //     min-width: 22vw;

                    //     & .cover {
                    //         max-width: 22vw;
                    //         max-height: 22vw;
                    //     }
                    // }

                    @media only screen and (min-width: 1551px) {
                        min-width: 300px;

                        & .cover {
                            min-width: 300px;
                            max-height: auto;
                        }
                    }
                }

                & > * + * {
                    margin-left: 16px;
                }

                .responsive("ant-md", {
                    & > * + * {
                        margin-left: 40px;
                    }
                });
            }
        }

        & .highlighted {
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;

            padding-top: 40px;

            .highlighted-header {
                display: flex;
                flex-direction: column;
                max-width: 550px;

                .responsive("ant-md", {
                    align-items: center;
                    margin: 0 auto;
                });

                .header-link {
                    display: flex;
                    margin-bottom: 10px;

                    color: @brand-navy;

                    font-weight: 600;
                    font-size: 16px;
                    line-height: 120%;
                    text-transform: uppercase;
                    text-decoration-line: underline;

                    .responsive("ant-md", {
                        margin-bottom: 15px;
                    });

                    .icon {
                        width: 15px;
                        margin-right: 5px;
                    }
                }

                .highlighted-title {
                    margin-top: 0;
                    margin-bottom: 10px;

                    color: @brand-navy;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 120%;

                    .responsive("ant-md", {
                        margin-bottom: 15px;

                        font-size: 30px;
                        text-align: center;
                    });

                    .title-link {
                        color: @brand-navy;
                    }
                }

                .highlighted-subtitle {
                    color: @grey-1;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 140%;

                    .responsive("ant-md", {
                        margin-bottom: 40px;

                        font-size: 20px;
                        text-align: center;
                    });

                    .promo-code {
                        color: @grey-1;
                        text-decoration: underline;
                    }

                    a {
                        color: @grey-1;
                        text-decoration: underline;
                    }
                }
            }

            & .highlighted-products {
                display: flex;
                // overflow-x: scroll;
                scrollbar-width: none; /* Firefox */
                -ms-overflow-style: none; /* Internet Explorer 10+ */

                &::-webkit-scrollbar {
                    /* WebKit */
                    display: none !important;
                    width: 0;
                    height: 0;
                }

                & .ProductListing {
                    flex: 1;
                    width: 100%;
                    min-width: 280px;
                    max-width: 280px;
                    height: 100%;

                    & .cover {
                        height: 100%;
                        max-height: 280px;

                        img {
                            max-width: 100%;
                            height: 280px;
                            max-height: 100%;
                            object-position: center center;

                            .responsive("ant-md", {
                                height: auto;
                            });
                        }
                    }

                    @media only screen and (min-width: 768px) and (max-width: 1000px) {
                        min-width: 0;
                        max-width: calc((100vw - (16px * 3)) / 2);

                        & .cover {
                            // min-height: 350px;
                            // max-height: calc((100vw - (16px * 3)) / 2);
                        }
                    }

                    @media only screen and (min-width: 1000px) {
                        min-width: 0;
                        max-width: 380px;

                        & .cover {
                            height: 240px;
                            // min-height: 380px;
                            // max-height: 380px;
                        }
                    }

                    @media only screen and (min-width: 1551px) {
                        min-width: 300px;

                        & .cover {
                            min-width: 300px;
                            max-height: auto;
                        }
                    }
                }

                & > * + * {
                    margin-left: 16px;
                }

                .responsive("ant-md", {
                    & > * + * {
                        margin-left: 40px;
                    }
                });
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;