@import "../../Global.less";

.BreakingNews {
    min-width: 100%;

    background-color: #f8f9fb !important;

    & .ant-card-head,
    & .body {
        border-color: #e1e5eb;
    }

    & > .ant-card-body {
        padding: 0 !important;
    }

    & .ant-card-head {
        padding-left: @offset-xs !important;

        background-color: #edf0f5;

        & .ant-card-head-wrapper {
            height: 48px;
        }

        & .ant-card-head-title {
            color: @grey-1;
            font-weight: bold;
        }
    }

    & .body {
        max-height: 928px;
        padding: 16px !important;
        overflow-y: scroll;
    }

    & .BreakingNewsItem {
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
    }

    /* Spacer to account for Safari mobile */
    .breaking-news-item-spacer {
        padding-right: 2px;

        border: 1px solid transparent;

        &::after {
            content: "";
        }
    }
}

.BreakingNewsMobile {
    .ant-card {
        border-radius: 0;
    }

    & .body {
        display: flex;
        padding: @offset-xs 0 @offset-xs @offset-xs !important;
        overflow-x: auto;

        & .BreakingNewsItem {
            margin-bottom: 0;

            &:not(:last-of-type) {
                margin-right: 16px;
            }

            &:last-of-type {
                margin-right: 0;
            }

            & .ant-card-body {
                width: 255px;
                height: 100%;

                & > .summary {
                    margin-bottom: 8px;
                }

                & > .date {
                    margin-top: auto;
                }
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;