@import "../../Global.less";

.NewsSearch {
    flex: 1 1;
    margin: 32px auto;
    padding: 0 15px;

    .responsive("ant-xl", {
        padding: 0 32px;
    });

    & .suggestion-col {
        margin-bottom: 24px;
        padding: 0 !important;
    }

    .component-headline {
        margin-bottom: 20px;

        color: #000;
        font-weight: 600;
        line-height: 100%;
    }

    & .header {
        display: flex;
        justify-content: center;
        width: 100%;

        & .ant-typography {
            width: 100%;
            margin-bottom: 32px;

            color: gray;
            font-weight: bold;
            font-size: 22px;
            line-height: 120%;
            text-align: center;

            .responsive("ant-md", {
                margin-bottom: 64px;
                font-size: 36px;
            });
        }
    }

    & .quote-group {
        display: flex;
        justify-content: space-evenly;
        padding: 0 16px;
    }

    .ScrollableArticles {
        .desktop-carousel .ant-carousel {
            .slick-next {
                right: 20px;
            }

            .slick-prev {
                left: 20px;
            }

            .slick-slider {
                height: 180px;
                .responsive("ant-md", {
                    height: 210px;
                });
            }
        }

        .GenericContentCard {
            width: 253px;
            height: 180px;

            .responsive("ant-md", {
                width: 100%;
                height: 210px;
            });
        }
    }

    .news-pagination {
        margin: 32px 0;
    }

    .divider {
        margin: 0;
    }

    .app-banner {
        border: none;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;