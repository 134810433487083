@import "../../Global.less";

.FeaturedStory {
    padding: 20px;

    background-color: @grey-6;

    .responsive("ant-lg", {
        padding: 0;
    });

    .featured--link {
        border-radius: 4px;
    }

    .featured--container {
        display: flex;
        flex-direction: column;

        box-shadow: 2px 4px 14px rgba(0, 0, 0, 0.15);

        .responsive("ant-lg", {
            flex-direction: row;
        });

        .featured--title-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 25px 50px;

            background-color: @brand-navy;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom-left-radius: 0;

            .responsive("ant-lg", {
                width: 262px;
                min-width: 262px;
                padding: 35px 25px 45px 25px;
                border-bottom-left-radius: 4px;
                border-top-right-radius: 0;
            });

            .torch-icon {
                margin-bottom: 12px;

                .responsive("ant-lg", {
                    margin-bottom: 16px;
                });
            }

            .featured--title {
                margin-bottom: 12px;

                color: @white;
                font-weight: 700;
                font-size: 20px;
                line-height: 110%;

                .responsive("ant-lg", {
                    margin-bottom: 16px;
                });
            }

            .featured--subtitle {
                max-width: 212px;
                margin-bottom: 0;

                color: @white;
                font-weight: 400;
                font-size: 15px;
                line-height: 130%;
                text-align: center;
            }
        }

        .featured--thumbnail-block {
            width: 100%;
            height: auto;
            padding: 20px;
            overflow: hidden;

            background-color: white;

            .responsive("ant-lg", {
                padding: 0;
            });

            .featured--thumbnail-image {
                width: 100%;
                min-width: 241px;
                height: 100%;
                object-fit: cover;
            }
        }

        .featured--story-block {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            padding: 0 20px 20px;

            background-color: @white;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;

            .responsive("ant-lg", {
                padding: 20px 20px 15px 30px;
                border-bottom-left-radius: 0;
                border-top-right-radius: 4px;
            });

            .content-container {
                .featured--story-title {
                    margin-bottom: 12px;

                    color: @brand-navy;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 120%;
                }

                .featured--story-description {
                    color: @grey-1;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 120%;
                }
            }

            .featured--story-footer {
                padding-top: 15px;

                border-top: 1px solid @grey-6;

                .responsive("ant-lg", {
                    padding-top: 10px;
                });

                .footer-container {
                    display: flex;
                    align-items: center;

                    .footer-icon {
                        margin-right: 8px;
                    }

                    .footer-text {
                        margin-bottom: 0;

                        color: @grey-1;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 130%;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;