.VideoPlayer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;

    @media screen and (max-width: 500px) {
        padding: 0 1em;
    }

    @media (orientation: landscape) and (max-width: 960px) {
        flex-direction: row-reverse;
    }

    & > .loader,
    & > .error {
        position: absolute;

        p {
            text-align: center;

            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .invisible {
        visibility: hidden;
    }

    .close {
        width: 640px;
        margin-bottom: 1em;

        text-align: right;

        cursor: pointer;

        svg {
            fill: #fff;
        }

        @media screen and (max-width: 500px) {
            width: 100%;
            margin-bottom: 1em;
        }

        @media (orientation: landscape) and (max-width: 960px) {
            width: 10%;
            margin-left: auto;

            text-align: center;
        }
    }

    .video-player-wrapper {
        width: 640px;
        height: 400px;

        > div {
            width: 100%;
            height: 100%;
        }

        @media screen and (max-width: 500px) {
            height: 300px;
        }

        & .vjs-big-play-button {
            width: 64px;
            height: 64px;

            background-color: unset;
            border-color: #fff;
            border-width: 4px;
            border-radius: 50%;
            //background      : url('../../../static/icons/circle-play-icon.svg') no-repeat top left;;
        }

        & .amp-default-skin .vjs-big-play-button::before {
            font-size: calc(0.03em);
            text-shadow: unset;

            @media screen and (max-width: 768px) {
                font-size: calc(0.04em);
            }

            @media screen and (max-width: 640px) {
                font-size: calc(0.08em);
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;