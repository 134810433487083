.HorizontalItem {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
    margin-bottom: 16px;

    h5.AntTitle.ant-typography.HorizontalItemTitle {
        display: -webkit-box;
        margin-top: 0;
        margin-bottom: 8px;
        overflow: hidden;

        color: #222;
        font-weight: bold;
        font-size: 12px;
        font-family: "Source Sans Pro";
        line-height: 1.3em;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }

    a {
        display: contents;
    }

    .item-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 0;
    }
}

.HorizontalItemImg {
    display: flex;
    flex-shrink: 0;
    width: 96px;
    height: 64px;
    margin-right: 12px;
    object-fit: cover;

    border-radius: 2px;
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;