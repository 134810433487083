@import "../../Global.less";

.ProductSearch {
    & .ShopUsaMenu-mobile {
        margin-bottom: 0;
    }

    & > .Container.ProductSearch-content {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        .responsive("ant-md", {
            flex-direction: row;
            margin-bottom: 52px;
        });

        & .products {
            width: 100%;

            & .items {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                width: 100%;
                margin-top: -8px;
                margin-right: -16px;

                & > * {
                    flex: 1 0 calc(50% - 16px);
                    max-width: calc(50% - 16px);
                    margin-top: 24px;
                    margin-right: 16px;
                }

                .responsive("ant-md", {
                    margin-top: -20px;
                    margin-right: -20px;

                    & > * {
                        flex: 1 0 calc(50% - 20px);
                        max-width: calc(50% - 20px);
                        margin-top: 20px;
                        margin-right: 20px;
                    }
                });

                .responsive("ant-lg", {
                    margin-top: -40px;
                    margin-right: -40px;

                    & > * {
                        flex: 1 0 calc(50% - 40px);
                        max-width: calc(50% - 40px);
                        margin-top: 40px;
                        margin-right: 40px;
                    }
                });
            }

            & .pagination {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 56px;
            }

            .no-products {
                margin-top: 16px;

                color: @brand-navy;
                text-align: center;
            }
        }

        & .filters {
            display: flex;
            flex-direction: column-reverse;

            .responsive("ant-md", {
                flex-direction: column;
                max-width: 284px;
                margin-right: 40px;

                & > * + * {
                    margin-top: 32px;
                    padding-top: 32px;

                    border-top: 1px solid @grey-5;
                }
            });

            .responsive("ant-lg", {
                margin-right: 128px;
            });

            & .group {
                display: flex;
                align-items: flex-start;

                .responsive("ant-md", {
                    flex-direction: column;
                });

                &-title {
                    margin-bottom: 8px;

                    color: #081a33;
                    font-weight: bold;
                    font-size: 30px;
                    line-height: 120%;
                }

                &-subtitle {
                    color: #081a33;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 130%;
                    text-align: center;
                }

                &-body {
                    color: @grey-2;
                    font-size: 16px;
                    line-height: 140%;
                }

                & .filter-link {
                    .responsive("ant-md", {
                        margin-top: 8px;
                    });

                    color: @grey-1;
                    font-size: 16px;
                    line-height: 140%;
                    text-align: center;

                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                & .filtered-link {
                    .responsive("ant-md", {
                        margin-top: 8px;
                    });

                    color: @brand-red;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 140%;
                    text-align: center;
                    text-decoration: underline;

                    cursor: pointer;
                }

                &--subcategories {
                    width: 100vw;
                    margin-left: -16px;
                    padding: 8px 16px;
                    overflow-y: scroll;

                    white-space: nowrap;

                    background-color: @grey-6;

                    & > * + * {
                        margin-left: 16px;
                    }
                }
            }

            .toggle-group {
                display: flex;
                margin-top: 16px;

                &--mobile {
                    & > * + * {
                        margin-left: 4px;
                    }
                }

                &--desktop {
                    & > * + * {
                        margin-left: 8px;
                    }
                }

                & .price {
                    border-radius: 4px;
                }
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;