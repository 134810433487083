@import "../../Global.less";

.TopicsCard {
    position: relative;

    width: 100%;
    overflow: hidden;

    .red-line {
        position: absolute;
        top: 0;
        right: 0;

        width: 100%;
        height: 8px;

        background-color: @brand-red;
    }

    .title {
        margin-top: 8px;

        color: @grey-1;
        font-weight: bold;
        line-height: 120%;
    }

    .topic {
        display: flex;
        align-items: center;
        height: 44px;
        padding: 8px;

        border-radius: 4px;
        // box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
        cursor: pointer;

        &:not(:last-child) {
            margin: 16px 0;
        }
    }

    .circle {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 28px;
        min-width: 28px;
        height: 28px;
        min-height: 28px;
        margin: 0;

        color: @white;
        font-weight: bold;
        font-size: 14px;

        background-color: @brand-red;
        border-radius: 50%;
    }

    .topic-name {
        display: inline-block;
        margin: 0 0 0 8px;

        &:hover {
            text-decoration: underline;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;