@import "../../Global.less";

.FeaturePreviewItem {
    display: flex;
    flex-direction: column;
    min-width: 255px;
    height: 100%;

    /* QA feedback - no scrolling on desktop */
    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        min-width: 100%;
    }

    & .subtitle {
        color: @brand-red;
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        .max_lines(1);
    }

    & .title {
        .max_lines(4);

        margin-top: 8px !important;
        margin-bottom: 8px !important;

        cursor: pointer;

        & a {
            color: @grey-1;
            font-weight: 400;
            font-size: 14px;
            font-style: normal;
            line-height: 140%;
        }
    }

    & .foot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;

        & span.ant-typography {
            color: @grey-2;
            font-weight: 400;
            font-size: 14px;
            font-style: normal;
            line-height: 100%;
        }
    }

    & .ant-card-body {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 12px;
    }

    & .ant-card-cover img {
        width: 100%;
        height: 150px;
        max-height: 150px;
        object-fit: cover;
    }

    @media screen and (max-width: 600px) {
        & .foot {
            & span.ant-typography {
                font-size: 12px;
            }
        }

        & .title {
            & a {
                font-size: 14px;
            }
        }

        & .subtitle {
            font-size: 12px;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;