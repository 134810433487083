@import "../../Global.less";

.ShareDropdown {
    & .ant-popover-inner-content {
        padding: 0;
    }

    & .ant-popover-inner {
        overflow: hidden;

        border-radius: 4px;
    }

    & .ant-popover-arrow {
        display: none;
    }
}

.ShareMenuItem {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 12px 18px;

    background-color: #fff;
    cursor: pointer;

    & .ant-typography {
        font-size: 16px;
    }

    & .ant-typography,
    & svg {
        color: #3b4355;
    }

    & svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }

    &:hover {
        background-color: #edf0f5;

        & .ant-typography,
        & svg {
            color: #3b4355;
        }
    }

    &:active {
        background-color: #3b4355;

        & .ant-typography,
        & svg {
            color: #fff;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;