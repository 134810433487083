@import "../../Global.less";

.OtherContent {
    min-width: 0;

    .navy-color-text {
        a {
            color: @brand-navy !important;
        }
    }
}

.OtherContent > * {
    margin: 24px 0;

    @media only screen and (min-width: 641px) and (max-width: 1260px) {
        margin: 24px 0;
    }

    @media only screen and (max-width: 640px) {
        margin: 16px 0;
    }

    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;