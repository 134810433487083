@import "../../Global.less";

.TweetCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    scroll-snap-stop: always;

    text-align: left;

    background-color: @white;
    border: 1px solid #e5e5ea;
    border-radius: 4px;

    .responsive("ant-sm", {
        scroll-snap-align: center;
    });

    .responsive("ant-lg", {
        scroll-snap-stop: unset;
    });

    .tweet-card-header {
        display: flex;
        justify-content: space-between;
        margin: 16px 16px 26px;

        .tweet-header-left {
            display: flex;

            & > .tweet-card-author {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 12px;

                color: @grey-1;

                line-height: 1.2;

                .tweet-card-author-title {
                    .max_lines(1);
                    .no_wrap();
                    margin-right: 4px;

                    font-weight: bold;
                    font-size: 16px;
                }

                .username {
                    .max_lines(1);
                    .no_wrap();

                    font-size: 14px;
                }
            }

            & > svg {
                margin-left: auto;
            }

            & > img {
                margin-right: 5px;

                border-radius: 50%;
            }
        }

        .twitter-card-logo {
            & .anticon-twitter {
                color: #1da1f2;
                font-size: 16px;
            }

            .text {
                color: @grey-2;
                font-weight: 700;
                font-size: 14px;
            }
        }
    }

    .tweet-card-body {
        margin: 0 10px 16px;

        & > a {
            color: @grey-1;
            font-size: 16px;
            line-height: 1.4;
            text-decoration: none;
        }

        .tweet-body-text {
            margin-bottom: 0;
        }
    }

    .tweet-card-divider {
        margin: auto 0 0 !important;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;