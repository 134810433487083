@import "../../Global.less";

.GenericContentCard {
    display: flex;

    overflow: hidden;

    border-radius: 5px;

    .image-container {
        position: relative;

        width: 100%;

        .gradient-bg {
            position: absolute;
            top: 0;

            width: 100%;
            height: 100%;

            background: linear-gradient(179.55deg, rgba(0, 0, 0, 0) 44.16%, rgba(0, 0, 0, 0.6) 99.61%);
        }

        .image-loader {
            width: 100%;

            height: 100%;
        }

        .card-title {
            position: absolute;
            bottom: 5px;
            left: 20px;

            margin-right: 20px;

            color: white;
            font-weight: 700;
            font-size: 16px;
            line-height: 130%;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;