@import "../../Global.less";

.ScrollableArticles {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;

    .responsive("ant-md", {
        display: block;
        flex-wrap: nowrap;
    });

    & .mobile-carousel {
        display: flex;
        // padding-bottom: 12px;
        overflow-x: scroll;

        & > * + * {
            // Add spacing between children
            margin-left: 16px;
        }

        .responsive("ant-md", {
            & > * + * {
                // Add spacing between children
                margin-left: 16px;
            }
        });

        /* Firefox */
        * {
            scrollbar-width: auto;
            scrollbar-color: @grey-5 @white;
        }

        /* Chrome, Edge, and Safari */
        *::-webkit-scrollbar {
            height: 8px;
        }

        *::-webkit-scrollbar-track {
            background: transparent;
        }

        *::-webkit-scrollbar-thumb {
            width: 190px;

            background-color: @grey-5;
            border: none;
            border-radius: 999px;
        }

        & .trigger-ref {
            margin: 0;
        }

        .TweetCard {
            min-width: 255px;
        }
    }

    & .desktop-carousel {
        .slick-disabled {
            display: none !important;
        }

        // Hacky solution to get carousel to position correctly
        .ant-carousel .slick-slider .slick-track,
        .ant-carousel .slick-slider .slick-list {
            display: flex;
            margin-right: -16px;
        }

        // Slide styles
        .ant-carousel .slick-initialized .slick-slide {
            // Add 16px spacing between slides (including last slide for react-slick calculation)
            float: none;
            margin-right: 16px;

            // Force all carousel children to match height
            > div {
                height: 100%;
            }
        }

        // Carousel back button
        .ant-carousel .slick-prev {
            left: -20px;

            &::before {
                // Back button icon
                content: url("../../../static/icons/chevron-left.svg");
            }
        }

        // Carousel next button
        .ant-carousel .slick-next {
            right: -20px;

            &::before {
                // Next button icon
                content: url("../../../static/icons/chevron-right.svg");
            }
        }

        // Shared styles for carousel back/next buttons
        .ant-carousel .slick-prev,
        .ant-carousel .slick-next {
            top: 50%;
            z-index: 10;

            width: 40px;
            height: 40px;

            background-color: @white;
            border-radius: 50%;
            box-shadow: 0 0 16px rgba(45, 45, 45, 0.24);

            &::before {
                position: absolute;
                right: 0;
                bottom: 30%;
                left: 0;
                z-index: 3;

                color: @grey-1;

                &:hover,
                &:focus {
                    color: @grey-1;

                    background-color: @white;
                }
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;