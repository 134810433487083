@import "../../Global.less";

.ImageSearchFilter {
    .settings-button {
        display: flex;
        align-items: center;
        padding: 4px 2px;

        color: @grey-1;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: right;

        border: none;

        .settings-icon {
            display: flex;
            align-items: center;
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
    }
}

.SettingsDropdown {
    width: 100%;
    padding-bottom: 0;

    border: @border-gray-1;
    border-radius: 0;

    .responsive("ant-lg", {
        width: auto;
    });

    &.ant-dropdown {
        z-index: 2;
    }

    .ant-dropdown-menu {
        padding: 0 80px 20px 20px;

        .responsive("ant-lg", {
            padding: 32px 92px 32px 32px;
        });
    }

    .ant-dropdown-menu-item {
        padding: 0;

        cursor: auto;

        &:hover {
            background-color: unset;
        }
    }

    .ant-dropdown-menu-item-group-title {
        padding: 0;
    }

    .close-button-container {
        position: absolute;
        top: 20px;
        right: 26px;

        padding: 0;

        border-radius: 30px;

        .responsive("ant-lg", {
            top: 32px;
            right: 32px;
        });

        .close-button {
            // position: absolute;
            // top: 20px;
            // right: 20px;

            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: auto;

            padding: 0;

            border: none;
            border-radius: 30px;
            cursor: pointer;
        }
    }

    .settings-field {
        padding: 20px 20px 20px 0;

        border-bottom: @border-gray-1;

        .responsive("ant-lg", {
            width: 308px;
            padding: 20px 30px 20px 0;
        });

        .flex-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        label {
            color: @grey-1;
            font-weight: 700;
            font-size: 16px;
            line-height: 130%;

            .responsive("ant-lg", {
                top: 32px;
                right: 32px;
            });
        }

        .ant-switch-checked {
            background-color: @brand-red;
        }

        .description-text {
            margin-top: 20px;
            margin-bottom: 0;

            color: @grey-1;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            white-space: normal;
            word-break: break-all;

            .description-title {
                font-weight: 600;
                font-style: italic;
            }
        }

        .select-container {
            position: relative;

            select {
                width: 125px;
                margin-left: 10px;
                padding: 6px 36px 6px 16px;
                overflow: hidden;

                font-weight: 400;
                font-size: 16px;

                white-space: nowrap;
                text-overflow: ellipsis;

                background-image: url("../../../static/icons/chevron-down-small.svg");
                background-repeat: no-repeat;
                background-position: calc(100% - 16px) center;

                border: @border-gray-1;

                border-radius: 4px;
                cursor: pointer;

                -webkit-appearance: none;

                .responsive("ant-lg", {
                    padding: 6px 34px 6px 16px;
                    width: 150px;
                    max-width: 150px;
                });

                &.color-select {
                    padding-left: 48px;
                }
            }

            .color-indicator {
                position: absolute;
                top: 25%;
                left: 24px;

                width: 20px;
                height: 20px;

                border-radius: 2px;

                &.all,
                &.coloronly {
                    background-image: url("../../../static/images/color-wheel.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 150%;
                }

                &.monochrome {
                    background-image: url("../../../static/images/color-wheel-monochrome.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100%;
                }

                &.black {
                    background-color: black;
                }

                &.blue {
                    background-color: blue;
                }

                &.brown {
                    background-color: brown;
                }

                &.gray {
                    background-color: grey;
                }

                &.green {
                    background-color: green;
                }

                &.orange {
                    background-color: orange;
                }

                &.pink {
                    background-color: pink;
                }

                &.purple {
                    background-color: purple;
                }

                &.red {
                    background-color: red;
                }

                &.teal {
                    background-color: teal;
                }

                &.white {
                    background-color: @white;
                    border: @border-gray-1;
                }

                &.yellow {
                    background-color: yellow;
                }
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;