@import "../../Global.less";

.NewsSearchResult {
    display: flex;
    width: 100%;
    margin: 0;
    overflow: hidden;

    border: none !important;
    border-radius: 4px;

    .titleContainer {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .ant-card-body {
        display: flex;
        max-width: 100%;
        padding: 0;
    }

    .newsLetterImage {
        margin-right: 16px;

        border-radius: 4px;

        img {
            min-height: 100%;
        }
    }

    .newsLetterImage.large {
        width: 110px;
        height: 110px;
    }

    .newsLetterImage.small {
        width: 90px;
        height: 90px;
    }

    .publisherDetails {
        display: flex;
        align-items: center;
    }

    .publisherIcon {
        margin-right: 8px;
    }

    .label-section {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .responsive("ant-sm", {
            margin-right: 15px;
        });

        span {
            line-height: 100%;
        }

        .label {
            font-weight: bold;
        }

        .right {
            margin-left: 5px;

            color: #c43351;
        }

        .middle {
            color: #3b4355;
        }

        .left {
            margin-left: 5px;

            color: #2168cc;
        }

        .pro {
            color: #3b4355;
        }
    }

    .bias-section.small {
        font-size: 12px;
    }

    .title {
        margin-top: 4px;

        color: @grey-1;
        font-weight: 600;
        font-size: 21px;
        line-height: 120%;

        &:hover {
            text-decoration: underline;
        }
    }

    .title.small {
        font-size: 14px;
        line-height: 130%;
    }

    .description {
        margin-bottom: 8px;

        color: @grey-2;
        font-size: 16px;
        line-height: 140%;
        .max_lines(2);

        &--large {
            .max_lines(3);
        }
    }

    .result_highlight {
        font-weight: bold;
    }

    .articleInfo {
        display: flex;
        align-items: center;
        margin-top: auto;
    }

    .datePublished {
        display: inline-flex;
        margin-right: 10px;

        color: @grey-1;
        font-size: 14px;
        white-space: nowrap;

        .responsive("ant-sm", {
            color: @grey-2;
            margin-right: 15px;
        });
    }

    .datePublished.small {
        font-size: 12px;
    }

    .url {
        color: @grey-1;
        .max_lines(1);

        .responsive("ant-sm", {
            color: @grey-2;
        });
    }

    .publisherName {
        color: #3b4355;
        font-size: 14px;
        line-height: 140%;
        .max_lines(1);
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;