@checkbox_color: #c43351;

.AcceptTermsCheckbox {
    .form-checkbox {
        color: #fff;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.4;

        & a {
            color: inherit;
            text-decoration: underline;

            &:hover {
                color: inherit;
            }
        }

        & .ant-checkbox-checked .ant-checkbox-inner {
            background-color: @checkbox_color;
            border-color: @checkbox_color;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;