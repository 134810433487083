@import "../../Global.less";

.Category {
    display: flex;
    flex: 1;

    justify-content: center;

    width: 100%;
    max-width: 1200px;
    height: 100%;

    margin: 0 auto;
    overflow: hidden;
    .responsive("<desktop", {
        flex-direction: column;
        justify-content: unset;
        margin-bottom: 20px;
    });
    .responsive("mobile", {
        margin-bottom: 8px;
    });

    .category-loader {
        height: 200px;
        margin-top: 5em;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;