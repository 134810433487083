@import "../../Global.less";

.SpangldModal {
    .ant-modal-content {
        width: 600px;
        height: 600px;

        margin: 0 auto;
        overflow: hidden;

        border-radius: 4px;

        @media only screen and (max-width: 640px) {
            width: 100%;
            height: unset;
        }
    }

    .ant-modal-header {
        border-bottom: 1px solid #e1e5eb;

        .ant-modal-title > .SpangldTake {
            background: #fff;

            .spangld-take-header {
                border: 0;

                @media only screen and (min-width: 641px) {
                    font-size: 22px;
                    line-height: 120%;
                }
            }
        }
    }

    .ant-modal-body {
        height: 100%;
        padding: 0;
        overflow-y: auto;
    }
}

.SpangldPreview {
    display: flex;
    flex-direction: column;

    .SpangldTop {
        display: flex;
        flex-direction: row;
        padding: 24px;

        border-bottom: 1px solid #e1e5eb;

        @media only screen and (max-width: 640px) {
            flex-direction: column;
            padding: 16px;
        }

        .SpangldImage,
        .SpangldInfo {
            width: 50%;

            @media only screen and (max-width: 640px) {
                width: 100%;
            }
        }

        .SpangldImage {
            & > .ImageLoader {
                height: 100%;
            }
        }

        .SpangldInfo {
            display: flex;
            flex-direction: column;
            margin-left: 16px;

            @media only screen and (max-width: 640px) {
                margin-top: 16px;
                margin-left: 0;
            }

            & > .SpangldLink {
                margin-top: auto;

                text-align: right;

                button.ant-btn {
                    border-radius: 4px;
                }
            }
        }

        .SpangldTitle {
            color: #000;
            font-weight: bold;
            font-size: 24px;
            font-family: "Source Sans Pro";
            font-style: normal;
            line-height: 120%;

            @media only screen and (max-width: 640px) {
                color: #3b4355;
            }
        }

        .TopicSummary {
            display: -webkit-box;
            margin-bottom: 16px;

            overflow: hidden;

            color: #000;
            font-size: 16px;
            font-family: "Source Sans Pro";
            line-height: 140%;

            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;

            text-overflow: ellipsis;

            @media only screen and (max-width: 640px) {
                color: #3b4355;
            }
        }
    }

    .SpangldBody {
        padding: 24px;

        h3 {
            color: #3b4355;
            font-weight: bold;
            font-size: 22px;
            font-family: "Source Sans Pro";
            line-height: 120%;
        }

        .SpangldSummaryList {
            padding-bottom: 40px;
            overflow: hidden;

            color: #3b4355;
            font-size: 16px;
            font-family: "Source Sans Pro";
            line-height: 140%;

            h2 {
                line-height: 130%;
            }

            li {
                margin-bottom: 8px;
            }

            img {
                max-width: 100%;
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;