.Footer {
    width: 100%;
    margin-top: auto;
    padding: 16px;

    background-color: #081a33;

    @media only screen and (min-width: 641px) {
        padding: 16px 24px;
    }

    .container {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        @media screen and (max-width: 768px) {
            flex-direction: column;

            & .copy {
                margin-right: 0 !important;
                margin-bottom: 8px;
            }

            & .links {
                margin-bottom: 8px;

                & a.ant-typography {
                    margin-right: 8px;

                    &:last-of-type {
                        margin-right: 0;
                    }

                    &::after {
                        cursor: default;
                    }

                    &:not(:last-of-type)::after {
                        margin-left: 8px;

                        content: "\2022";
                    }
                }
            }

            & .love {
                margin-left: 0 !important;
            }
        }

        & .copy {
            margin-right: 24px;

            color: #fff !important;
            font-size: 12px;
        }

        & a.ant-typography {
            margin-right: 24px;

            color: #fff !important;
            font-size: 12px;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        & .links {
            display: flex;
            align-items: center;
        }

        & .love {
            margin-left: auto;

            color: #fff !important;
            font-size: 12px;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;