@import "../../Global.less";

.ShopUsaBreadcrumbs {
    span:last-child span {
        color: @grey-2 !important;
    }
}

.breadcrumb-item {
    color: @grey-1;
    font-size: 16px;

    a {
        color: @grey-1;
        font-size: 16px;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;