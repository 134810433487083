.SpangldSummary {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 641px) {
        flex-direction: row;
        align-items: center;
    }

    .SpangldTake {
        flex-shrink: 0;

        @media only screen and (max-width: 640px) {
            margin-bottom: 8px;
        }
    }

    .SpangldTake > .spangld-take-header {
        flex-shrink: 0;
        padding: 16px;

        color: #3b4355;
        font-weight: bold;
        font-size: 14px;
        line-height: 130%;
        letter-spacing: 0.05em;

        @media only screen and (max-width: 640px) {
            max-width: 100%;
            margin-left: 0;
            padding: 8px 6px;
        }
    }

    .SummaryWrap {
        margin-left: 16px;

        @media only screen and (max-width: 640px) {
            margin-left: 0;
        }

        .Summary {
            display: -webkit-box;
            margin-bottom: 0;
            overflow: hidden;

            color: #3b4355;
            font-weight: 400;
            font-size: 16px;
            font-family: "Source Sans Pro";
            line-height: 140%;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;

            @media only screen and (max-width: 640px) {
                font-size: 15px;
                line-height: 140%;
            }
        }

        span.SpangldView {
            margin-bottom: 16px;

            color: #c43351;
            font-weight: 400;
            font-size: 14px;
            font-family: "Source Sans Pro";
            line-height: 140%;
            text-decoration: underline;

            cursor: pointer;

            @media only screen and (max-width: 640px) {
                margin-bottom: 8px;

                font-size: 13px;
                line-height: 140%;
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;