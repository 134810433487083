@import "../../Global.less";

.AppBarHeader {
    position: relative;

    display: flex;
    justify-content: center;
    padding: 16px;

    .responsive("ant-lg", {
        padding: 16px 32px;
    });

    background-color: @brand-navy;

    & .max-wrap {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;

        & > * + * {
            margin-left: 16px;

            .responsive("ant-lg", {
                margin-left: 32px;
            });
        }

        & .SearchBar {
            flex: 1;

            & .ant-input-affix-wrapper {
                height: 36px;

                line-height: 36px;

                & .ant-input-prefix {
                    margin-right: 8px;
                    margin-left: 0;
                }

                & input {
                    font-size: 14px;
                    line-height: 14px;
                }

                .responsive("ant-lg", {
                    height: 54px;
                    line-height: 54px;

                    & .ant-input-prefix {
                        margin-right: 16px;
                    }

                    & input {
                        font-size: 16px;
                        line-height: 16px;
                    }
                });
            }
        }

        & .logo {
            display: flex;

            & svg {
                width: auto;
                height: 37.6px;

                color: @white;

                .responsive("ant-lg", {
                    height: 32.31px;
                });
            }
        }

        & .Hamburger {
            margin: -16px -16px -16px 0;
            padding: 16px;

            .responsive("ant-lg", {
                margin: -16px -16px -16px 16px;
            });
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;