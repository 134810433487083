.MobileLayout {
    padding: 0;
    scroll-margin-top: 60px;
    scroll-snap-margin-top: 60px; // Safari < 14.1

    & > .spinner {
        display: flex;
        justify-content: center;
    }

    & .BreakingNewsCol {
        flex: 1;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;