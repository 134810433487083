@import "../../Global.less";

.StoryCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ant-card-body {
        width: 100%;
        padding: 16px;

        background: @grey-7;

        border-radius: 4px;

        .responsive("ant-sm", {
            padding: 25px 30px;
        });
    }

    .header {
        display: flex;
        align-items: center;

        margin-bottom: 10px;

        svg {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            margin-left: -5px;
        }

        span {
            color: @grey-2;
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
        }
    }

    .link {
        display: flex;
        justify-content: space-between;

        margin-bottom: 10px;

        a {
            width: 100%;
        }

        .title {
            width: 90%;
            margin-bottom: 0;

            color: @brand-navy;

            font-weight: 600;
            font-size: 16px;
            line-height: 120%;

            .responsive("ant-sm", {
                font-size: 21px;
                line-height: 115%;
            });
        }

        .view-story-button {
            width: 100px;
            height: 40px;
            padding: 10px 20px;

            color: @white;

            font-weight: 700;
            font-size: 14px;
            line-height: 18px;

            white-space: nowrap;
            text-align: center;

            background: @brand-blue;
            border-radius: 2px;
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .view-story-link {
            color: @brand-navy;

            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            text-decoration: underline;
        }

        .updated-date {
            color: @grey-2;

            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;