@import "../../Global.less";

.EmailCapture {
    display: flex;
    flex-direction: column;

    & .title {
        margin-bottom: 16px;

        font-weight: bold;
        font-size: 48px;
        line-height: 110%;

        span {
            color: #c43351;
        }
    }

    & .title-small {
        font-weight: bold;
        font-size: 28px;
        font-style: normal;
        line-height: 120%;
    }

    & .subtitle {
        margin-bottom: 40px;

        font-size: 30px;
    }

    & .subtitle-small {
        margin-bottom: 28px;

        font-weight: normal;
        font-size: 16px;
        font-style: normal;
        line-height: 140%;
    }

    & .text-white {
        color: #fff;
    }

    & .text-center {
        text-align: center;
    }

    & .ant-form-item-control {
        height: auto;
    }

    & .AcceptTermsCheckbox {
        height: auto;
        margin-top: 16px;
    }

    & .ant-form-item-explain {
        margin-top: 16px;

        text-align: center;
    }

    & .ant-form-item {
        margin-bottom: 0;
    }

    & .success {
        position: absolute;
        z-index: 10;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;

        text-align: center;

        background-color: #b4d3ff;
        border-radius: @border-radius;
    }

    & .action-area {
        position: relative;

        max-width: 443px;
        overflow: hidden;

        .ant-input-affix-wrapper-lg {
            height: 48px;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;