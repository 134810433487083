@import "../../Global.less";

.AlertPill {
    display: flex !important;
    align-items: center;
    justify-content: center;

    min-height: 19px;

    margin-left: 8px !important;

    color: #fff !important;
    font-weight: bold;
    font-size: 10px !important;
    line-height: 110% !important;
    letter-spacing: 0.05em;

    text-transform: uppercase;

    border-radius: 22px !important;
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;