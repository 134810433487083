@import "../../Global.less";

.CategoryCard {
    overflow: hidden;

    border: 1px solid #e1e5eb !important;
    border-radius: 4px !important;

    & .ImageLoader {
        height: 200px;

        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    & .ant-card-body {
        padding: 16px;
    }

    & .ant-collapse-item {
        display: flex;
        flex-direction: column-reverse;
    }

    & .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
        padding: 0;

        color: #c43351;
        font-size: 16px;
        line-height: 140%;
        text-decoration-line: underline;
    }

    & .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }

    & .ant-card-meta-title {
        color: #3b4355;
        font-weight: bold;
        font-size: 20px;
        line-height: 120%;
    }

    & .ant-card-meta-description {
        & a.ant-typography.TopicLink {
            display: block;
            width: 100%;
            margin-bottom: 6px;

            color: #3b4355;
            font-size: 16px;
            line-height: 140%;
            .max_lines(2);
        }

        & a.ant-typography.TopicLink:hover {
            text-decoration: underline;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;