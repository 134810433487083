@import "../../Global.less";

.SearchBarItem {
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    padding: 8px;

    color: #3b4355;
    font-weight: normal;
    font-size: 16px;
    font-style: normal;
    line-height: 100%;

    border: 1px solid #e1e5eb;
    border-radius: 4px;

    cursor: pointer;

    transition: 500ms;

    &:hover {
        background-color: #f8f9fb;
    }

    span {
        color: @grey-1;
    }

    & .ant-avatar {
        margin-right: 8px;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;