@import "../../Global.less";

.JoinTheNewsLetterBanner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-height: 477px;

    padding: 50px 20px 130px;

    background: url("../../../static/images/gozha-net-xDrxJCdedcI-unsplash.jpeg"), rgba(8, 26, 51, 0.55);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-size: cover;
    background-blend-mode: multiply;

    .responsive("ant-sm", {
        background-position: center;
    });

    .title {
        margin-bottom: 10px;

        color: @white;
        font-weight: bold;
        font-size: 30px;
        line-height: 120%;
        text-align: center;

        .responsive("ant-sm", {
            font-size: 36px;
        });
    }

    .tagline {
        margin-bottom: 50px;

        color: @white;
        font-weight: 600;
        font-size: 18px;
        font-style: normal;
        line-height: 140%;
        text-align: center;

        .responsive("ant-sm", {
            font-size: 24px;
        });
    }

    .controls-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & > * + * {
            margin-left: 20px;
        }

        .email-input-container {
            flex: 1;

            .ant-input {
                height: 58px;
                .responsive("ant-md", {
                    width: 403px;
                });
            }

            .ant-input-affix-wrapper {
                padding: 0;
                padding-right: 16px;
            }

            .mail-icon {
                margin-right: 16px;
                margin-left: 26px;
            }
        }

        .submit-button {
            width: 56px;
            height: 60px;

            .text {
                display: none;
                margin: 0;

                color: @white;

                font-weight: bold;
                font-size: 18px;
                line-height: 18px;
            }
            .responsive("ant-md", {
                width: 220px;
                .icon {
                    display: none;
                }
                .text {
                    display: inline;
                }
            });
        }
    }

    .AcceptTermsCheckbox {
        .ant-form-item-control-input-content {
            text-align: center;

            .form-checkbox {
                font-size: 16px;

                .ant-checkbox-inner {
                    background-color: #212123;

                    border-color: transparent;
                }
            }
        }
    }

    .data-info {
        margin-top: 50px;

        color: @white;
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
    }

    .success {
        display: flex;
        flex-direction: column;

        & > * + * {
            margin-top: 20px;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;