@import "../../Global.less";

.SearchPage {
    display: block;
    width: 100%;
    min-width: 100vw;
    max-width: 1264px;

    .responsive("ant-xl", {
        min-width: 1200px;
        margin: 0 auto;
    });

    .ant-tabs-ink-bar {
        background: #1890ff;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #1890ff;
    }

    .tabs-style {
        .ant-tabs-nav {
            .responsive("ant-xl", {
                margin: 8px 32px;
            });
            margin: 8px 24px 0;

            .tab-option {
                display: flex;
                align-items: center;
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;