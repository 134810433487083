@import "../../Global.less";

.ViewStoryCard {
    display: block;
    min-width: 255px;
    max-width: 300px;
    padding: 24px 16px;

    background-color: @grey-7;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: @border-radius;

    .inner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding: 24px;

        background-color: @white;
        border: @border-gray-1;
        border-radius: @border-radius;

        .view-story-icon {
            min-width: 32px;
            min-height: 32px;
            margin-bottom: 20px;
        }

        .story-title {
            margin-bottom: 10px;

            color: @grey-1;
            font-weight: 600;
            font-size: 16px;
            line-height: 130%;
            text-align: center;
        }

        .view-story-text {
            display: flex;
            align-items: center;
            margin-bottom: 0;

            color: @grey-2;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;

            .arrow-right-icon {
                width: 15px;
                margin-left: 5px;
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;