@import "../../Global.less";

.FeaturePreviewBox {
    border: none !important;
    border-radius: 0 !important;

    .responsive("ant-lg", {
        border-radius: @border-radius !important;
    });

    & .ant-row {
        min-width: 813px;
    }

    .scroll-area {
        overflow-x: scroll;

        .ant-row {
            padding-right: @offset-xs;
        }

        .scroll-area-inner-container {
            padding: 0 @offset-xs;

            .responsive("ant-lg", {
                padding: 0;
            });
        }
    }

    .ant-card-body {
        padding: 16px 12px;

        .responsive("ant-lg", {
            padding: 20px;
        });

        &:first-of-type {
            padding: @offset-xs 0;

            .responsive("ant-lg", {
                padding: 20px;
            });
        }
    }

    & .head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        padding: 0 @offset-xs;

        .responsive("ant-lg", {
            margin-bottom: 24px;
            padding: unset;
        });

        & .ant-typography {
            color: @white !important;
        }

        & .about {
            display: flex;
            flex-direction: column;

            .heading-link {
                margin-bottom: 4px;

                color: @white;
                font-weight: 700;
                font-size: 30px;
                font-style: normal;
                line-height: 120%;
                text-decoration: none;

                @media only screen and (max-width: 640px) {
                    margin-bottom: 0;
                }
            }

            .description-text {
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
            }

            .view-more-link {
                margin-left: 5px;

                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
            }
        }

        & a.ant-typography {
            margin-bottom: auto;

            color: @white;
            font-weight: normal;
            font-size: 14px;
            font-style: normal;
            line-height: 140%;
            text-decoration: underline;
            text-decoration-line: underline;
        }

        & h3.ant-typography {
            margin-bottom: 4px;
        }
    }

    /*  For <DesktopLayout />  */
    .responsive("ant-lg", {
        border-radius: @border-radius;

        & .scroll-area {
            margin-right: 0;
            overflow-x: unset;

            .ant-row {
                padding-right: 0;
            }
        }
    });

    .share-compressed-btn {
        margin-top: 10px;
        padding-left: 0;

        .responsive("ant-sm", {
            margin: unset;
        });

        .btn-text {
            margin-right: 10px;
        }
    }

    /* QA feedback - no scrolling on desktop */
    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        & .ant-row {
            min-width: 100%;
        }
    }

    @media screen and (max-width: 1024px) {
        & .scroll-area {
            overflow-x: scroll;
        }
    }

    @media screen and (max-width: 600px) {
        & .head {
            flex-direction: column;
            padding-right: 16px;

            & a.ant-typography {
                margin-top: 8px;
                margin-bottom: 0;
            }
        }
    }
}

.FeaturePreviewBox.censored-stories {
    background: @brand-navy !important;

    .about .description {
        margin-top: 5px;
    }
}

.FeaturePreviewBox.best-of-the-web {
    background: @brand-red !important;

    .about .description {
        margin-top: 5px;
    }

    .FeaturePreviewItem {
        .subtitle {
            color: @grey-1 !important;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;