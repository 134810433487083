@import "../../Global.less";

@keyframes slide-out-blue-bg {
    0% {
        position: absolute;

        top: 0;
        right: 0;
    }

    100% {
        position: absolute;

        top: 0;
        right: -87vw;
    }
}

@keyframes slide-out-img {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

@keyframes slide-out-img-full-opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.about-us(@color) {
    color: @color;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-align: center;

    & .link {
        color: @color;
        text-decoration: underline;
        .lighten-pseudo(@color);
    }
}

.SearchScreen {
    position: relative;
    z-index: 1;

    width: 100%;

    .banner-section {
        position: relative;

        min-width: 100vw;

        height: 100vh;

        background-color: @brand-navy;
        mix-blend-mode: darken;

        .responsive("ant-md", {
            padding-bottom: 0;
        });

        .responsive("ant-lg", {
            height: 80vh;
        });
    }

    input::placeholder {
        font-size: 16px !important;
        .responsive("ant-md", {
            font-size: inherit;
        });
    }

    & .scroll-for-news {
        position: absolute;
        bottom: 1vh;
        left: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        transform: translateX(-50%);
        cursor: pointer;

        & .label {
            margin-right: 8px;

            color: @grey-1;
            font-weight: 400;
            font-size: 15px;
            line-height: 14px;
        }

        & .chevron-down {
            width: 16px;
            height: 16px;

            color: @grey-1;
        }
    }

    & .freespoke-story {
        position: absolute;
        bottom: 0;
        z-index: 2;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: auto;

        padding: 20px;

        background: linear-gradient(90.27deg, rgba(13, 39, 73, 0.5) 20.4%, rgba(8, 23, 46, 0.5) 63.43%);
        opacity: 0;

        animation: slide-out-img-full-opacity 3s;
        animation-delay: 1s;
        animation-fill-mode: forwards;

        .responsive("ant-xs", {
            flex-direction: row;
        });

        .responsive("ant-md", {
            height: 70px;
            padding: 0 20px;
        });

        .label {
            flex-shrink: 2;
            margin-right: 10px;
            margin-bottom: 16px;

            color: @white;

            font-size: 16px;
            text-align: center;

            .responsive("ant-xs", {
                margin-bottom: 0;
                text-align: left;
            });
        }

        .link {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 219px;

            min-width: 219px;
            height: 38px;
            padding: 10px 15px;

            color: @white;

            background: rgba(8, 26, 51, 0.8);
            border-radius: 4px;

            .logo {
                display: flex;
                align-items: center;

                font-weight: 700;
                font-size: 14px;
            }

            svg {
                height: 20px;
                margin-right: 5px;
            }
        }
    }

    & .container {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-right: 16px;
        padding-left: 16px;

        .slider {
            position: absolute;

            top: 0;
            right: 0;
            z-index: 2;

            width: 87vw;
            height: 100vh;

            background-color: @brand-navy;

            .responsive("ant-lg", {
                height: 80vh;
            });
        }

        .slider-out {
            animation: slide-out-blue-bg 3s;
            animation-delay: 1s;
            animation-fill-mode: forwards;
        }

        & .navigation {
            position: absolute;
            top: 18px;
            right: 15px;

            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: flex-end;

            width: 100%;

            .responsive("ant-md", {
                right: 50px;
            });

            .nav-item {
                height: 34px;

                color: #fff;
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;

                background: none;
                border: none;

                .responsive("ant-md", {
                    font-size: 17px;
                    height: 48px;
                });
            }

            .app-btn {
                margin-right: 20px;

                img {
                    height: 34px;
                    .responsive("ant-md", {
                        height: 48px;
                    });
                }
            }

            .nav-buttons {
                height: auto;
                margin-right: 30px;

                .responsive("ant-md", {
                    font-size: 16px;
                });

                .responsive("ant-lg", {
                    margin-right: 50px;
                });
            }

            .share-button {
                margin-right: 45px;
                padding: 0;

                // background: rgba(8, 26, 51, 0.5);
                .responsive("ant-md", {
                    font-size: 16px;
                });

                .icon {
                    vertical-align: middle;
                }

                .ShareCompressed {
                    svg path {
                        fill: #fff;
                    }

                    > svg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;

                        width: 100%;
                        height: 100%;

                        opacity: 0;
                    }
                }
            }

            .join-button {
                height: 34px;

                color: #fff;
                font-weight: 400;
                font-size: 12px;

                .responsive("ant-md", {
                    font-size: 15px;
                    height: 48px;
                });
            }

            .Hamburger {
                .responsive("ant-md", {
                    margin-left: 50px;

                });
                margin-left: 15px;
            }
        }

        & .freespoke-beta {
            width: 272px;
            height: auto;
            margin-bottom: 40px;

            color: @white;

            opacity: 0;

            animation: slide-out-img-full-opacity 3.5s;
            animation-delay: 1s;
            animation-fill-mode: forwards;

            .responsive("ant-md", {
                width: 402px;
                margin-bottom: 60px;
            });
        }

        & .search-bar-wrapper {
            z-index: 4;

            width: 100%;
            max-width: 480px;
            margin-bottom: 8px;

            opacity: 0;

            animation: slide-out-img-full-opacity 3.5s;
            animation-delay: 1s;
            animation-fill-mode: forwards;

            .responsive("ant-md", {
                max-width: 720px;
            });
        }

        & .about-us {
            z-index: 3;

            margin-top: 24px;

            span {
                display: inline-block;
            }

            .about-us(@white);
        }
    }

    & .footer {
        position: absolute;
        bottom: 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 32px;
        padding-right: 16px;
        padding-left: 16px;

        background-color: @white;

        & .divider {
            width: 100%;
            height: 1px;
            margin-top: 32px;

            background-color: @grey-5;
        }

        & .about-us {
            .about-us(#3b4355);
        }
    }

    .picture-wrapper {
        animation: slide-out-img 5s;
        animation-fill-mode: forwards;
    }

    & .background-img {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center bottom;

        &:-moz-loading {
            visibility: hidden;
        }

        .responsive("ant-sm", {
            object-position: right bottom;
        });

        .responsive("ant-md", {
            object-position: 100%;
            padding-bottom: 0;
        });
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;