@import "../../Global.less";

.ContentCardV2 {
    position: relative;

    width: 100%;
    height: 100%;
    overflow: hidden;

    background: #fff;
    border: @border-gray-1;
    border-radius: @border-radius;

    & .image {
        position: relative;

        flex: 1 1;

        .ant-image-img {
            min-height: 180px;
        }
    }

    & .link-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    & .content {
        position: absolute;
        bottom: 0;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        padding: 0 12px;

        background: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 33.49%, rgba(0, 0, 0, 0) 48.89%);

        .publisher {
            display: flex;
            width: 100%;
        }

        .publisher-logo {
            margin-right: 8px;
        }

        .publisher-name {
            color: @white;
            font-weight: 400;
            font-size: 14px;
            .max_lines(1);
        }

        .title {
            .max_lines(2);

            margin: 7px 0 10px;

            color: @white;
            font-weight: 600;
            font-size: 16px;
            line-height: 130%;
        }
    }

    .no-overlay {
        background: none;
    }

    & .divider.ant-divider-horizontal {
        min-width: 100%;
        margin: auto;

        border-color: @grey-6;
    }

    .play-background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;