@import "../../Global.less";

.Hero {
    position: relative;

    display: flex;
    flex-direction: column;
    width: 100%;

    margin-right: auto;
    margin-left: auto;

    background: rgb(8, 26, 51);
    background-image: linear-gradient(0deg, rgba(8, 26, 51, 0.91), rgba(8, 26, 51, 0.91)), url("../../../static/images/hero.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .responsive("ant-lg", {
        background-image: linear-gradient(90deg, rgba(8, 26, 51, 1) 16%, rgba(8, 26, 51, 0.5)), url("../../../static/images/hero.jpg");
    });

    & > .hero-container {
        display: flex;
        flex-direction: column;
        width: 100vw;
        max-width: 1248px;
        margin: 0 auto;

        & > .EmailCapture {
            z-index: 2;

            max-width: 610px;
            margin-right: 64px;
            margin-bottom: 24px;
            margin-left: 24px;

            .subtitle {
                font-weight: bold;
            }
        }

        @media screen and (max-width: 600px) {
            & > .header {
                margin-bottom: 24px !important;
                margin-left: auto !important;

                font-size: 20px !important;
            }

            & > .EmailCapture {
                margin-right: 16px !important;
                margin-bottom: 16px !important;
                margin-left: 16px !important;

                & > .title {
                    margin-bottom: 8px;

                    font-size: 32px;
                    text-align: center;
                }

                & > .subtitle {
                    margin-bottom: 24px;

                    font-weight: 400;
                    font-size: 16px;
                    text-align: center;
                }
            }
        }

        & > .header {
            z-index: 2;

            margin: 48px auto 32px 24px;

            padding-bottom: 4px;

            color: #fff;
            font-weight: bold;
            font-size: 18px;
            line-height: 100%;
        }

        & .anticon-close {
            position: absolute;
            top: 16px;
            right: 16px;

            color: #fff;

            cursor: pointer;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;