@import "../../Global.less";

@menu-bg-color: #1a2b42;

& .AppBarMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    max-height: calc(var(--app-height) - var(--app-header-height));
    overflow-x: hidden;
    overflow-y: scroll;

    background-color: #081a33;

    .responsive("ant-lg", {
        display: flex;
        align-items: center;

        padding-left: 32px;
        padding-right: 32px;

        max-height: calc(var(--app-height) - var(--app-header-height)) !important;
    });

    & .link {
        color: @white;
        font-size: 16px;
        line-height: 140%;

        .lighten-pseudo(@menu-bg-color);
    }

    & .category-link {
        margin-bottom: 6px;

        color: @white;
        font-weight: bold;
        font-size: 20px;
        line-height: 120%;
    }

    & .social {
        display: flex;

        & > * + * {
            margin-left: 8px;
        }

        & .circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            margin-bottom: 16px;

            background-color: @grey-3;
            border-radius: 50%;

            & svg {
                width: 16px;
                height: 16px;

                color: @brand-navy;
            }

            .responsive("ant-lg", {
                margin-top: 16px;
                margin-bottom: 0;
            });
        }
    }

    & .topic-drawer {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 24px 16px;
        overflow-x: hidden;
        overflow-y: scroll;

        background-color: @menu-bg-color;

        & .back-button {
            margin-bottom: 24px;

            cursor: pointer;

            & svg {
                color: @white;
            }
        }

        & .category-link {
            margin-bottom: 0;

            font-size: 24px;
        }

        & .items {
            display: flex;
            flex: 1;
            flex-direction: column;

            & > * + * {
                margin-top: 16px;
            }
        }
    }

    & .navigation {
        display: flex;
        flex-direction: column;
        width: 100%;

        .responsive("ant-lg", {
            flex: 1;
            flex-shrink: 0;
            flex-direction: row;
            max-width: 1200px;
            padding-top: 40px;
            padding-bottom: 40px;
        });

        & .header {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;

            .responsive("ant-lg", {
                margin-bottom: 40px;
            });

            & .title {
                color: @white;
                font-weight: bold;
                font-size: 24px;
                line-height: 120%;
            }

            & .snippet {
                color: #9aa2b3;
                font-size: 14px;
                line-height: 140%;
            }

            & .flare {
                display: none;
                width: 64px;
                height: 1px;
                margin-top: 16px;

                background-color: #c43351;

                .responsive("ant-lg", {
                    display: block;
                });
            }

            & .flare-gap {
                margin-top: 35px;
            }
        }

        & .desktop-results {
            display: flex;
            justify-content: space-between;

            & .column {
                display: flex;
                flex-direction: column;

                & .category-link:not(:first-of-type) {
                    margin-top: 40px;
                }

                & .link:not(:last-of-type) {
                    margin-bottom: 6px;
                }

                &:not(:last-of-type) {
                    margin-right: 40px;
                }

                .responsive("ant-xl", {
                    &:not(:last-of-type) {
                        margin-right: 80px;
                    }
                });
            }
        }

        & .feature-links {
            display: flex;
            flex-direction: column;

            & > * + * {
                margin-top: 18px;
            }

            .responsive("ant-lg", {
                & > * + * {
                    margin-top: 6px;
                }
            });
        }

        .shop-cta {
            width: 100%;
            max-width: 283px;
            margin: 0 auto;
            margin-top: 12px;
            padding-top: 12px;
            overflow: hidden;

            .responsive("ant-lg", {
                border-top: 1px solid @grey-1;
                margin-top: 48px;
                padding-top: 48px;
            });

            & .image {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            & .info {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 24px;

                background-color: @white;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

                & .title {
                    color: @grey-1;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 120%;
                }

                & .desc {
                    margin-top: 4px;
                    margin-bottom: 24px;

                    color: @grey-3;
                    font-size: 14px;
                    line-height: 140%;
                }

                & .shop-now {
                    width: 100%;
                }
            }
        }

        & .features {
            padding: 16px;

            .responsive("ant-lg", {
                padding: 0;
                margin-right: 40px;
            });

            .responsive("ant-xl", {
                margin-right: 80px;
            });
        }

        & .freespoke-results {
            background-color: @menu-bg-color;

            .responsive("ant-lg", {
                flex: 1;
                flex-shrink: 0;
                background-color: #081a33;
            });

            & .header {
                margin-bottom: 0;
                padding: 16px;

                .responsive("ant-lg", {
                    padding: 0;
                    margin-bottom: 40px;
                });
            }
        }

        & .mobile-results {
            & .list-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px;

                border-top: 1px solid #3b4355;
                cursor: pointer;

                &:last-of-type {
                    border-bottom: 1px solid #3b4355;
                }

                & svg {
                    color: @white;
                }
            }
        }
    }

    & .footer {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-top: 24px;
        padding-bottom: 24px;

        .responsive("ant-lg", {
            flex-shrink: 0;
            flex-direction: row;
            width: 100%;
            max-width: 1200px;
            padding-bottom: 40px;
            margin-bottom: 0;
        });

        & .love {
            color: @white;
            font-size: 14px;
            line-height: 140%;
        }

        & .dot {
            margin-right: 8px;
            margin-left: 8px;

            color: @white;
            font-size: 12px;
            line-height: 140%;

            opacity: 0.5;
        }

        & .notice {
            color: @white;
            font-size: 12px;
            line-height: 140%;

            opacity: 0.5;
            text-decoration-line: underline;
        }
    }

    & .spinner {
        display: flex;
        align-items: center;
        justify-content: center;

        & .ant-spin-dot-item {
            background-color: @white;
        }
    }
}

.AppBarMenuLock {
    overflow: hidden;
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;