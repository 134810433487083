// Ant Design Breakpoints
// https://ant.design/components/layout/#breakpoint-width
@ant-xs: 480px; // mobile
@ant-sm: 576px; // mobile
@ant-md: 768px; // tablet
@ant-lg: 992px; // desktop
@ant-xl: 1200px; // desktop
@ant-xxl: 1600px; // desktop

// Design System Colors
// https://www.figma.com/file/BeNqCNfVZdWazxZn23MjJK/DS-Freespoke?node-id=1%3A24
@brand-navy: #081a33;
@brand-navy-9: lighten(@brand-navy, 85%);
@brand-navy-banner: rgba(10, 44, 90, 0.9);
@black-1: #212123;
@brand-red: #c43351;
@brand-blue: #2168cc;
@grey-1: #3b4355;
@grey-2: #7d8799;
@grey-3: #9aa2b2;
@grey-4: #b5bcc9;
@grey-5: #e1e5eb;
@grey-6: #edf0f5;
@grey-7: #f8f9fb;
@white: #fff;
@success: #1cbe6c;
@error: #f0594b;

@valentine-pink: #ff3ea5;
@border-gray-1: 1px solid #e1e5eb; // TODO: "gray" fix and grey-5
@border-radius: 5px;

// Offsets (used for padding and margins)
@offset-xs: 20px;

// Legacy Colors (to be removed)
@legacy-white: #fff;
@legacy-off-white: #f3f3f3;
@legacy-anso-bg: #f9f9f9;
@legacy-anso-stamp: #cc0000;
@legacy-lighter-gray: #d8d8d8;
@legacy-light-gray: #a4a4b0;
@legacy-border-gray: #979797;
@legacy-search-bar-gray: #4a4a4a;
@legacy-dark-gray: #333;
@legacy-darker-gray: #2b2929;
@legacy-main-bg-color: #1d2733;
@legacy-black: #000;
@legacy-primary-green: #26a099;
@legacy-green-blue: #26a099;
@legacy-dark-green: #008000;
@legacy-dark-red: #f82725;
@legacy-right-bias-color: #f82725;
@legacy-center-bias-color: #a013a5;
@legacy-left-bias-color: #0015dd;
@legacy-about-blue: #327ab7;
@legacy-yellow: #ffc107;
@legacy-publisher-grey-light: #caccd4;
@legacy-grey-shade-1: #3c3a45;
@legacy-grey-shade-2: #4f4f4f;
@legacy-search-link-green: #0b9390;
@legacy-search-text-grey: #171f29;

// Set hover, focus and active states (darken)
.darken-pseudo(@color) {
    &:hover,
    &:focus {
        color: darken(@color, 20%);
    }

    &:active {
        color: darken(@color, 40%);
    }
}

// Set hover, focus and active states (lighten)
.lighten-pseudo(@color) {
    &:hover,
    &:focus {
        color: lighten(@color, 30%);
    }

    &:active {
        color: lighten(@color, 40%);
    }
}

// Clamp lines using webkit box
.max_lines(@max_lines) {
    display: -webkit-box;
    overflow: hidden;

    text-overflow: ellipsis;
    -webkit-line-clamp: @max_lines;
    -webkit-box-orient: vertical;
}

// Prevent text from wrapping to newline
.no_wrap() {
    word-wrap: break-word;
    -ms-word-break: break-all;

    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;

    /* Instead use this non-standard one: */
    word-break: break-word;

    /* These are technically the same, but use both */
    overflow-wrap: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

// Apply a standardized box shadow
.box_shadow() {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.box_shadow-2() {
    box-shadow: 0 4px 4px rgba(8, 26, 51, 0.12);
}

// Apply a standardized box shadow on hover
.box_shadow_on_hover() {
    &:hover {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    }
}

// Append ellipses on text overflow
.ellipses() {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

// Slightly blurs the image and sets its transparency to 50%
// Requires a parent element with a set background color
.img_hover_overlay() {
    transition: opacity 0.1s ease-in-out, filter 0.1s ease-in-out;

    &:hover {
        opacity: 0.5;
        filter: blur(1px);
    }
}

// Sets its transparency to 90%
// Slightly zoom-in the image and requires a parent element with a set background color
.img_hover_overlay_with_scaling(@opacity: 1) {
    transition: opacity 300ms ease-in-out, 300ms transform ease-in-out;

    &:hover {
        transform: scale(1.025);
        opacity: @opacity;
    }
}

// Applied to <img /> elements. Sets the image to cover format.
.cover_img() {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
}

/*

Media query definitions

Example: Apply styles at antd xl (1200px) and above

.responsive("ant-xl", {
  width: 40px;
  height: 40px;
});

*/
.responsive(@breakpoint, @rules) {
    // Ant Design Breakpoints
    & when(@breakpoint = "ant-xs") {
        @media only screen and (min-width: @ant-xs) {
            @rules();
        }
    }

    & when(@breakpoint = "ant-sm") {
        @media only screen and (min-width: @ant-sm) {
            @rules();
        }
    }

    & when(@breakpoint = "ant-md") {
        @media only screen and (min-width: @ant-md) {
            @rules();
        }
    }

    & when(@breakpoint = "not:ant-md") {
        @media only screen and (max-width: calc(@ant-md + 1px)) {
            @rules();
        }
    }

    & when(@breakpoint = "ant-lg") {
        @media only screen and (min-width: @ant-lg) {
            @rules();
        }
    }

    & when(@breakpoint = "ant-xl") {
        @media only screen and (min-width: @ant-xl) {
            @rules();
        }
    }

    & when(@breakpoint = "ant-xxl") {
        @media only screen and (min-width: @ant-xxl) {
            @rules();
        }
    }

    // Legacy Breakpoints
    & when(@breakpoint = "mobile") {
        @media only screen and (max-width: 640px) {
            @rules();
        }
    }

    & when(@breakpoint = "<=mobile-md") {
        @media only screen and (max-width: 540px) {
            @rules();
        }
    }

    & when(@breakpoint = "<tablet-portrait") {
        @media only screen and (max-width: 769px) {
            @rules();
        }
    }

    & when(@breakpoint = "tablet") {
        @media only screen and (min-width: 641px) and (max-width: 1025px) {
            @rules();
        }
    }

    & when(@breakpoint = ">=tablet") {
        @media only screen and (min-width: 641px) {
            @rules();
        }
    }

    & when(@breakpoint = "desktop") {
        @media only screen and (min-width: 1026px) {
            @rules();
        }
    }

    & when(@breakpoint = "<desktop") {
        @media only screen and (max-width: 1025px) {
            @rules();
        }
    }

    & when(@breakpoint = ">=desktop") {
        @media only screen and (min-width: 1026px) {
            @rules();
        }
    }

    & when(@breakpoint = "desktop-sm") {
        @media only screen and (min-width: 1026px) and (max-width: 1259px) {
            @rules();
        }
    }

    & when(@breakpoint = "desktop-md") {
        @media only screen and (min-width: 1260px) and (max-width: 1440px) {
            @rules();
        }
    }

    & when(@breakpoint = "desktop-lg") {
        @media only screen and (min-width: 1441px) and (max-width: 1680px) {
            @rules();
        }
    }

    & when(@breakpoint = "desktop-xl") {
        @media only screen and (min-width: 1681px) {
            @rules();
        }
    }
}

input {
    &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
    }
}
