@import "../../Global.less";

.SearchBar {
    position: relative;

    display: flex;
    flex-direction: column;

    & .closeIcon {
        margin-right: 15px;

        svg {
            color: @grey-3;
            font-size: 10px;
        }
    }

    & .searchButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 100%;

        background-color: @brand-red;
        border-radius: 4px;

        svg {
            color: @white;
        }
    }

    & .dropdown {
        position: absolute;
        top: 50px;
        z-index: 10;

        width: 100%;

        background: #fff;
        border-radius: 4px;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
        transform-origin: top left;

        .responsive("ant-lg", {
            top: 56px;
        });

        .title {
            color: @grey-1;
            font-weight: 700;
            font-size: 14px;
            font-style: normal;
            line-height: 130%;
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }

        & .topics {
            display: flex;
            flex-wrap: wrap;
            margin-top: 16px;

            & > * {
                margin-right: 8px;
                margin-bottom: 8px;
            }
        }

        & .popular-search-term {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 10px;

            svg {
                width: 12px;
                height: 12px;
                margin-right: 16px;

                path {
                    color: @grey-1;
                }
            }

            span {
                color: @grey-1;
                font-weight: 400;
                font-size: 16px;
                font-style: normal;
                line-height: 140%;

                transition: color 100ms ease-in-out;

                &:hover {
                    color: @brand-navy;
                }
            }
        }
    }

    & .search-bar {
        height: 48px;

        border: none;
        border-radius: 4px;

        & .ant-input-prefix {
            margin-right: 12px;
            margin-left: 8px;

            color: #3b4355;

            svg {
                font-size: 16px;
            }

            @media only screen and (max-width: 410px) {
                margin-right: 8px;

                font-size: 12px;
            }

            @media only screen and (max-width: 310px) {
                font-size: 11px;
            }
        }

        & input.ant-input,
        & input.ant-input::placeholder {
            color: #7d8799;
            font-size: 16px;

            @media only screen and (max-width: 400px) {
                // font-size: 12px;
            }

            @media only screen and (max-width: 310px) {
                // font-size: 11px;
            }
        }
    }

    & > form > .ant-input-affix-wrapper-lg.search-bar {
        @media only screen and (max-width: 400px) {
            padding: 6.5px;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;