@import "../../Global.less";

.SuggestionText {
    color: @brand-navy;

    & em {
        display: inline-block;

        text-decoration: underline;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .search-query {
        display: inline-block;
        margin-right: 32px;

        color: @black-1;

        font-weight: 700;
        font-size: 30px;
        line-height: 1.2;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .did-you-mean {
        font-weight: 400;
        font-size: 22px;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;