@import "../../Global.less";

.CategoriesSearch {
    flex: 1 1;
    flex-direction: row;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;

    background-color: #f8f9fb;

    .responsive("mobile", {
        padding: 16px;
    });

    .CategoriesInner {
        width: inherit;
        max-width: 1248px;
        margin: 40px auto 48px;

        .responsive("mobile", {
            margin: 0;
        });
    }

    & .categories-header {
        width: 100%;
        margin-bottom: 40px;

        .responsive("mobile", {
            margin-bottom: 16px;
        });

        h1.ant-typography {
            margin-bottom: 0;

            color: #3b4355;
            font-weight: bold;
            font-size: 38px;
            line-height: 110%;
        }

        p.ant-typography {
            margin-bottom: 0;

            color: #3b4355;
            font-size: 16px;
            line-height: 140%;
        }
    }

    & .ant-spin {
        display: block;
        margin: 50px auto;
    }

    & .CategoryCard {
        margin-bottom: 24px;

        .responsive("mobile", {
            margin-bottom: 16px;
        });
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;