@import "../../Global.less";

.StoryPreviewCard {
    &:hover {
        background-color: rgba(255, 255, 255, 0.04) !important;

        transition: background-color 0.3s linear;
    }

    .card-container {
        height: 100%;

        background-color: rgba(255, 255, 255, 0.1);

        border-color: transparent;
        border-radius: 4px;

        .ant-card-body {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 18px 20px 20px;
        }

        .ant-divider {
            margin: 10px 0;

            border-color: @white;
            opacity: 0.2;
        }

        .title {
            margin-bottom: 20px;

            color: @white;
            font-weight: 600;
            font-size: 18px;
            line-height: 110%;
        }

        .heading {
            height: 80px;
        }

        .bias-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .left,
            .right {
                .ant-typography {
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 110%;
                }

                svg {
                    width: 12px;
                    height: 12px;
                    margin: 0 4px 2px;

                    vertical-align: middle;
                }
            }

            .left {
                .ant-typography {
                    color: #4d96fe;
                }

                svg path {
                    fill: #4d96fe;
                }
            }

            .right {
                .ant-typography {
                    color: #fc486d;
                }

                svg path {
                    fill: #fc486d;
                }
            }

            .updated-since {
                color: @grey-5;
                font-weight: normal;
                font-size: 12px;
                line-height: 100%;
            }
        }

        .items-container {
            display: flex;
            flex-direction: column;

            & > * + * {
                margin-top: 20px;
            }
        }

        .item {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            border-radius: 8px;

            .image {
                position: relative;

                min-width: 60px;
                min-height: 60px;
                margin-right: 15px;

                .ant-image-img {
                    width: 60px;
                    height: 60px;
                }

                .publisher-logo {
                    position: absolute;

                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    margin: auto;
                }
            }

            & .title {
                margin-bottom: 5px;

                color: @grey-7;
                font-weight: normal;
                font-size: 14px;
                line-height: 110%;

                .max_lines(3);
            }

            & .publisher-name {
                color: #adb3bd;
                font-weight: 600;
                font-size: 10px;
                line-height: 110%;

                .max_lines(1);
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;