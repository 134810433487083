@import "../../Global.less";

.ShareCompressedIcon {
    width: 16px;
    height: 16px;

    color: @grey-1;

    cursor: pointer;

    &:hover {
        filter: brightness(90%);
    }

    &:active {
        filter: brightness(80%);
    }

    &.light {
        color: @white;
    }
}

.ShareCompressed {
    .ant-btn {
        height: inherit;
        padding: 0 4px;

        &:hover {
            background-color: transparent;
        }

        span {
            color: @grey-1;
            font-size: 14px;
        }
    }

    .ant-btn-text {
        &:focus {
            background: transparent;
        }
    }

    .share-compressed-btn {
        display: flex;
        align-items: center;

        &:hover {
            filter: brightness(90%);
        }

        .btn-text {
            color: @grey-1;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;

            &.light {
                color: @white;
            }
        }
    }

    .share-button {
        display: flex;
        align-items: center;
        height: 36px;
        padding: 12px 20px;

        background-color: @white;

        .responsive("ant-md", {
            height: 44px;
        });

        .share-button-text {
            display: flex;
            align-items: center;

            color: @black-1;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;

            .responsive("ant-md", {
                font-size: 15px;
            });
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;