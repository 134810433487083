@import "../../Global.less";

.AppBar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;

    width: 100%;
    height: 0;

    & .AppBarHeader {
        position: relative;
        z-index: 20;
    }

    & .AppBarMenu {
        position: relative;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;