@import "../../Global.less";

.RelatedResults {
    display: flex;
    flex-direction: column;

    & .title {
        margin-bottom: 16px;

        color: #3b4355;
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
    }

    & .topics {
        display: flex;
        flex-wrap: wrap;
        margin: -4px;

        & .topic {
            flex: 1 0 calc(50% - 16px);
            margin: 4px;

            & button {
                width: 100%;
                height: 35px;

                color: #3b4355;
                font-size: 14px;
                line-height: 138%;

                background-color: #f8f9fb;
                border: 1px solid #e1e5eb;
                border-radius: 999px;

                &:hover,
                &:focus,
                &:active {
                    color: @brand-blue;

                    border-color: @brand-blue;
                }
            }
        }
    }

    .responsive("ant-md", {
        & .title {
            margin-bottom: 24px;
        }

        & .topics {
            margin: -8px;

            & .topic {
                flex: 1 0 calc(50% - 32px);
                margin: 8px;

                & button {
                    height: 55px;
                    font-size: 18px;
                    line-height: 130%;
                }
            }
        }
    });
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;