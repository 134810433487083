@import "../../Global.less";

.StoryPageNewsFeed {
    max-width: 1000px;
    margin: 0 auto;

    & > .title {
        margin: 20px 0;

        color: #3b4355;
        font-weight: 700;
        font-size: 20px;

        font-family: "Source Sans Pro";
        font-style: normal;
        line-height: 120%;
        text-align: center;

        .responsive("ant-md",  {
            font-size: 24px;
            margin: 60px 0 50px;

        });
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;