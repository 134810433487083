@import "../../Global.less";

.TopStoriesPage {
    background: linear-gradient(180deg, @brand-navy 0%, rgba(10, 44, 90, 0.8) 100%), @brand-navy;

    .responsive("ant-lg", {
        width: 80%;
        margin: 0 auto;
        padding: 40px 0;

        background: white;
    });

    .spinner {
        text-align: center;
    }

    .col-container {
        display: flex;

        .col-left {
            padding-right: 30px;

            border-right: 1px solid @grey-5;
        }

        .col-right {
            padding-top: 50px;
            padding-left: 30px;
        }
    }

    .header {
        padding: 20px;

        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .responsive("ant-lg", {
            padding: 20px 20px 16px 20px;
            border-bottom: none;
        });

        .link-container {
            display: flex;
            align-items: center;

            .header-link {
                width: 18px;
                margin-bottom: 10px;

                .responsive("ant-lg", {
                    display: flex;
                    align-items: center;
                    width: auto;
                    margin-bottom: 16px;

                    color: @grey-1;
                });

                svg {
                    width: 18px;
                    height: 13.5px;
                    margin-right: 20px;

                    color: @grey-4;

                    .responsive("ant-lg", {
                        color: @grey-1;
                    });
                }
            }
        }

        .header-text {
            margin-bottom: 10px;

            color: @grey-4;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;

            .responsive("ant-lg", {
            });
        }

        .header-title {
            margin: 0;

            color: @white;
            font-weight: 700;
            font-size: 26px;
            line-height: 100%;

            .responsive("ant-lg", {
                color: @grey-1;
                font-size: 30px;
                line-height: 120%;
            });
        }
    }

    .body {
        padding: 20px 20px 10px;

        .stories-list {
            padding: 0;
        }

        .top-story-container {
            display: flex;
            margin-bottom: 16px;
            padding-bottom: 16px;

            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            .responsive("ant-lg", {
                border-bottom: none;
            });

            .story-header {
                display: flex;
                justify-content: space-between;

                margin-bottom: 16px;
            }

            .item-number-block {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                min-width: 30px;
                height: 30px;
                min-height: 30px;
                margin-right: 16px;

                color: @white;
                font-weight: 900;
                font-size: 14px;
                line-height: 9px;

                background: rgba(219, 231, 249, 0.1);
                border-radius: 50%;

                .responsive("ant-lg", {
                    color: @grey-1;
                    background: @grey-6;
                });
            }

            .title-block {
                .story-title {
                    display: block;
                    margin-bottom: 8px;

                    color: @white;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 130%;

                    .responsive("ant-lg", {
                        color: @grey-1;
                    });
                }
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;