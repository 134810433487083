@import "../../Global.less";

.StoryPage {
    flex: 1 1;

    background-color: @white;

    .content-container {
        margin: 30px 0;
        padding: 0 20px;

        .responsive("ant-lg",{
            padding: 0;
            max-width: 950px;
            margin: 40px auto 40px auto;
        });
        .responsive("ant-xl", {
            max-width: 1020px;
        });
    }

    .trending-news {
        .header {
            .responsive("ant-sm", {
                text-align: center;
            });
        }

        .news-title {
            color: @grey-1;

            font-weight: 700;
            font-size: 16px;

            .responsive("ant-sm", {
                font-size: 24px;

            });
        }

        .caption {
            font-weight: 400;
            font-size: 16px;
        }
    }

    .max-width-related {
        margin: 0 auto;
        padding: 0 20px;

        .responsive("ant-lg",{
            max-width: 950px;
            padding: 0;
        });
        .responsive("ant-xl",{
            max-width: 1020px;
        });
    }

    .bias-main-container {
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        .responsive("ant-md",{
            flex-direction: row;
            margin-top: 30px;

            & > * + * {
                // Add spacing between children
                margin-left: 30px;
            }

        });

        .bias-title {
            margin-top: 30px;
            margin-bottom: 20px;

            .responsive("ant-md", {
                margin-top: 0;
                margin-bottom: 0;
            });

            color: @grey-2;
            font-weight: bold;
            font-size: 20px;
            line-height: 120%;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            &.right {
                order: 1;
            }

            &.left {
                order: 3;
            }

            &.middle {
                order: 5;
            }

            &.right,
            &.left,
            &.middle {
                .responsive("ant-md",{
                    order: 0;
                });
            }
        }

        .bias-container {
            display: flex;
            flex: 1;
            flex-direction: row;
            margin-top: 0;

            overflow-x: auto;

            & > * + * {
                // Add spacing between children
                margin-left: 20px;
            }

            .responsive("ant-sm",{
                margin-top: 15px;
            });

            .responsive("ant-md",{
                flex-direction: column;
                overflow-x: hidden;
                padding-right: 0;
                margin-top: 0;

                & > * + * {
                    margin-left: 0;
                    margin-top: 30px;
                }
            });

            &.right {
                order: 2;
            }

            &.left {
                order: 4;
            }

            &.middle {
                order: 6;
            }

            &.right,
            &.left,
            &.middle {
                .responsive("ant-md",{
                    order: 0;
                });
            }

            .ContentCard,
            .TweetCard {
                flex: 1;
                min-width: 230px;
                height: auto;
                max-height: 310px;

                .headline {
                    .max_lines(2);
                    font-size: 18px;
                }

                .tweet-body-text {
                    font-size: 18px;
                }

                .responsive("ant-sm",{
                    min-width: 250px;

                    .headline {
                        .max_lines(3);
                    }
                });

                .responsive("ant-md",{
                    min-width: 200px;
                    height: 330px;
                    flex: none;
                });
            }
        }
    }

    .app-banner {
        border-top: 1px solid @grey-5;
        border-bottom: 1px solid @grey-5;
    }
}

.StoryPageSpin {
    width: min-content;
    margin: 0 auto;
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;