@import "../../Global.less";

.ProductCard {
    display: flex;
    flex-direction: column;

    & .cover {
        position: relative;

        width: 100%;
        height: 440px;
        overflow: hidden;

        background-color: black;
        border-radius: 4px;

        &--small {
            width: 176px;
            height: 176px;

            // keeps the last subcategory box item half visible on different screen width - so users know it's scrollable
            @media only screen and (min-width: 400px) and (max-width: 574px) {
                max-width: 35vw;
                max-height: 35vw;
            }

            @media only screen and (min-width: 575px) and (max-width: 779px) {
                max-width: 25vw;
                max-height: 25vw;
            }

            @media only screen and (min-width: 780px) and (max-width: 1009px) {
                max-width: 20vw;
                max-height: 20vw;
            }

            @media only screen and (min-width: 1010px) and (max-width: 1199px) {
                max-width: 16vw;
                max-height: 16vw;
            }

            @media only screen and (min-width: 1200px) {
                max-width: unset;
                max-height: unset;
            }
        }

        & .image {
            opacity: 0.8;
            .cover_img();
            .img_hover_overlay_with_scaling(1);
        }

        & .title {
            position: absolute;
            top: 50%;
            left: 50%;

            width: 80%;

            color: @white;
            font-weight: bold;
            font-size: 30px;
            line-height: 110%;
            text-align: center;

            transform: translate(-50%, -50%);

            pointer-events: none;

            .responsive("ant-md", {
                font-size: 38px;
                width: auto;
            });

            &--small {
                font-size: 20px;
                line-height: 120%;
            }
        }
    }

    & .subtitle {
        margin-top: 16px;

        color: #3b4355;
        font-size: 16px;
        line-height: 140%;

        .responsive("ant-md", {
            font-size: 20px;
        });

        &:active,
        &:focus,
        &:hover {
            color: #3b4355;
            text-decoration: underline;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;