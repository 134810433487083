@import "../../Global.less";

.WikiCard {
    display: flex;

    & .wrapper {
        display: flex;
        max-width: 100%;
        margin-bottom: 16px;
        overflow: hidden;
    }

    & .image {
        display: inline-block;
        width: 100px;
        min-width: 100px;
        height: 100px;
        min-height: 100px;
        margin-right: 12px;

        background-image: none;
        border-radius: 5px;

        img {
            object-fit: contain !important;
            object-position: center !important;
        }
    }

    & .title {
        align-self: center;

        font-weight: 700;
        word-wrap: break-word;
        word-break: break-word;
        overflow-wrap: break-word;
    }

    & .description {
        color: @grey-1;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
    }

    & .divider {
        margin-top: 16px !important;
        margin-bottom: 16px !important;
    }

    .footer {
        color: @grey-1;
        font-weight: 300;
        font-size: 12px;
        line-height: 140%;

        a {
            color: @grey-1;
            font-weight: 400;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;