@import "../../Global.less";

.WebSearchResult {
    width: 100%;

    .distance-source {
        display: flex;
        align-items: center;
        margin-top: 4px;

        & .bias {
            display: flex;
            align-items: center;
            margin-right: 8px;

            & .bias-icon {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }

            & .bias-label {
                font-weight: bold;
                font-size: 14px;
                line-height: 130%;
                letter-spacing: 0.05em;
                text-transform: uppercase;
            }

            & .bias-label-left {
                color: #2168cc;
            }

            & .bias-label-middle {
                color: #3b4355;
            }

            & .bias-label-right {
                color: #c43351;
            }
        }

        span:first-of-type {
            margin-right: 8px;
            margin-bottom: 0;

            color: #3b4355;
            font-size: 14px;
            line-height: 120%;
        }

        a.ant-typography {
            display: -webkit-box;
            max-width: 75%;
            overflow: hidden;

            color: #7d8799;
            font-size: 14px;
            line-height: 120%;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        a.ant-typography:hover,
        a.ant-typography:focus {
            color: #7d8799;
        }
    }

    h5.ant-typography.AntTitle {
        display: -webkit-box;
        margin: 0 0 4px;
        overflow: hidden;

        font-weight: 700;
        font-size: 20px;
        font-family: "Source Sans Pro";
        line-height: 1.3;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        a.searchResultTitleLink,
        .searchResultTitleLink a {
            color: #2168cc;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    span.ant-typography {
        display: -webkit-box;
        margin-bottom: 0;
        overflow: hidden;

        color: #7d8799;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4em;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .deep-links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 20px 0 0;
        .responsive("ant-md", {
            margin: 20px 0;
        });

        .deep-link {
            position: relative;

            .responsive("ant-lg", {
                margin-bottom: unset;
            });

            .ant-collapse-content > .ant-collapse-content-box {
                padding: 0;
            }

            .deep-link-header {
                display: flex;
                align-items: flex-start;
            }

            div.ant-collapse-header,
            a.ant-typography {
                display: -webkit-box;
                padding-left: 4px;
                overflow: hidden;

                color: #2168cc;
                font-weight: 700;
                font-size: 16px;
                line-height: 20.8px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;

                .responsive("ant-md", {
                    margin-bottom: 4px;
                    padding-left: unset;
                });

                svg {
                    color: black;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            a.sub-deep-link {
                display: -webkit-box;
                padding: 0 0 16px 16px;
                overflow: hidden;

                color: #2168cc;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                text-align: left;

                .responsive("ant-md", {
                    display: inline-flex;
                    padding: unset;
                });

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;