@import "../../Global.less";

.Container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;

    // 1200px +64px to account for 32px L/R padding at max width
    max-width: 1264px;
    margin: 0 auto;
    padding: 0 16px;

    .responsive("ant-lg", {
        padding: 0 32px;
    });
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;