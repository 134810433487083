.ImageLoader {
    position: relative;

    overflow: hidden;

    img {
        color: transparent;

        :-moz-loading {
            visibility: hidden;
        }
    }

    .ant-image {
        width: 100%;
        height: 100%;

        .ant-image-img {
            height: 100%;
            object-fit: cover;
            object-position: top;

            text-indent: -9999px; /* to hide the alt text on error */
        }
    }

    .ant-image-error {
        .ant-image-img {
            object-fit: contain;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;