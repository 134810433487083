@import "../../Global.less";

.AppDownloadBanner {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;

    border-top: 1px solid @grey-5;
    border-bottom: 1px solid @grey-5;

    .responsive("ant-lg", {
        max-width: unset;
        padding: 30px 0;
    });

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 374px;
        height: auto;

        border: 1px solid @grey-5;
        border-radius: 4px;

        .responsive("ant-lg", {
            flex-direction: row;
            height: 110px;
            width: auto;
            max-width: unset;
        });
    }

    .text-block {
        height: 174px;
        padding: 20px;

        background: linear-gradient(328.22deg, rgba(0, 0, 0, 0.07) 24.84%, rgba(0, 0, 0, 0) 43.91%);
        background-image: url("../../../static/images/app-download-banner-phones-mobile.png");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 60%;

        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .responsive("ant-lg", {
            display: flex;
            align-items: center;

            min-width: 550px;
            height: auto;
            padding-left: 28px;

            background-image: url("../../../static/images/app-download-banner-phones.png");
            background-position: 100% 42%;
            border-top-left-radius: 4px;
            border-top-right-radius: 0;
            border-bottom-left-radius: 4px;
        });

        .text-container {
            display: flex;
            flex-direction: column;
            max-width: 274px;
        }

        .title {
            margin-bottom: 16px;

            color: @brand-navy;
            font-weight: 700;
            font-size: 22px;
            line-height: 100%;
        }

        .subtitle {
            margin-bottom: 0;

            color: @brand-navy;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;

            @media only screen and (max-width: 340px) {
                max-width: 120px;
            }
        }
    }

    .cta-block {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;

        background: linear-gradient(91.72deg, rgba(60, 100, 156, 0.5) 23.79%, rgba(32, 67, 116, 0.375) 66.91%), @brand-navy;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;

        @media only screen and (max-width: 350px) {
            flex-direction: column;
        }

        .responsive("ant-lg", {
            width: 448px;
            height: auto;

            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 0;
        });

        .AppDownloadBadge {
            &:first-of-type {
                margin-right: 10px;

                @media only screen and (max-width: 350px) {
                    margin-right: 0;
                    margin-bottom: 12px;
                }

                .responsive("ant-lg", {
                    margin-right: 30px;
                });
            }

            .download-link {
                img {
                    height: 42px;

                    .responsive("ant-lg", {
                        height: 50px;
                    });
                }
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;