@import "../../Global.less";

.ShopUsaMenu-desktop {
    position: relative;

    & .header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 16px;

        border-bottom: 1px solid @grey-5;

        & .header-link {
            color: @grey-1;
            font-weight: bold;
            font-size: 16px;
            line-height: 130%;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        & > * + * {
            margin-left: 20px;
            padding-left: 20px;

            border-left: 1px solid @grey-5;

            .responsive("ant-lg", {
                margin-left: 40px;
                padding-left: 40px;
            });
        }
    }

    .ShopUsaBreadcrumbs {
        margin-top: 26px;
        padding: 0;
    }

    & .dropdown {
        position: absolute;
        left: 0;
        z-index: 1;

        width: 100%;

        background-color: @white;
        box-shadow: 0 16px 16px rgba(45, 45, 45, 0.16);
        transform-origin: top;

        & .dropdown-title {
            margin-bottom: 24px;

            color: @grey-1;
            font-weight: bold;
            font-size: 24px;
            line-height: 120%;
        }

        & .Container {
            flex-direction: row;
            padding-top: 40px;
            padding-bottom: 40px;

            & .subcategories {
                display: flex;
                flex: 1;
                flex-direction: column;
                max-width: 280px;

                & .subcategory-link {
                    color: @grey-1;
                    font-size: 16px;
                    line-height: 140%;

                    &:not(:last-of-type) {
                        margin-bottom: 8px;
                    }
                }
            }

            & .features {
                display: flex;
                flex: 1;
                flex-direction: column;

                & .featured-products {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: -28px;
                    margin-right: -40px;

                    & > * {
                        flex: 1 0 calc(50% - 40px);
                        margin-top: 16px;
                        margin-right: 40px;
                    }
                }
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;