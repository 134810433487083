@import "../../Global.less";

.TopStories {
    overflow: hidden;

    background: linear-gradient(180deg, @brand-navy 0%, rgba(10, 44, 90, 0.8) 100%), @brand-navy;

    .responsive("ant-lg", {
        max-width: 300px;

        border: 1px solid @grey-5;
        border-radius: 4px;

        background: white;
    });

    .header {
        display: flex;
        justify-content: space-between;
        padding: 24px 20px 20px;

        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .responsive("ant-lg", {
            padding: 20px 16px 16px;
            border-bottom: @border-gray-1;
            background-color: @grey-7;
        });

        .header-title {
            margin: 0;

            color: @white;
            font-weight: 700;
            font-size: 16px;
            line-height: 130%;
            letter-spacing: 0.04em;
            text-transform: uppercase;

            .responsive("ant-lg", {
                color: @grey-1;
            });
        }

        .header-link {
            color: @white;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            text-decoration-line: underline;

            .responsive("ant-lg", {
                color: @grey-1;
                font-size: 14px;
            });
        }
    }

    .body {
        padding: 20px;

        .responsive("ant-lg", {
            padding: 20px 16px 10px 16px;
        });

        .stories-list {
            padding: 0;
        }

        .top-story-container {
            display: flex;
            margin-bottom: 16px;
            padding-bottom: 16px;

            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            .responsive("ant-lg", {
                display: block;
                padding-bottom: 0;
                border-bottom: @border-gray-1;
            });

            &:last-of-type {
                .responsive("ant-lg", {
                    border: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
                });
            }

            .story-header {
                display: flex;
                justify-content: space-between;

                margin-bottom: 16px;
            }

            .item-number-block {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                min-width: 30px;
                height: 30px;
                min-height: 30px;
                margin-right: 16px;

                color: @white;
                font-weight: 900;
                font-size: 14px;
                line-height: 9px;

                background: rgba(219, 231, 249, 0.1);
                border-radius: 50%;

                .responsive("ant-lg", {
                    width: 28px;
                    min-width: 28px;
                    height: 28px;
                    min-height: 28px;

                    color: @grey-1;
                    background-color: @grey-6;
                });
            }

            .title-block {
                .story-title {
                    display: block;
                    margin-bottom: 8px;

                    color: @white;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 130%;

                    .responsive("ant-lg", {
                        margin-bottom: 10px;

                        color: @grey-1;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 130%;
                    });
                }
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;