.TrendingArticles {
    width: 100%;
    padding: 0;

    @media only screen and (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
    }

    .ant-divider-horizontal {
        margin: 40px 0;
        padding: 0 16px;

        border-color: #e1e5eb;

        @media only screen and (min-width: 641px) and (max-width: 1260px) {
            margin: 24px 0;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;