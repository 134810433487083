@import "../../Global.less";

.EmailHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    & .max-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: @ant-xl;
        padding: 32px 16px;

        .responsive("ant-md", {
            padding: 80px 40px;
        });

        & .title {
            margin-bottom: 8px;

            color: @white;
            font-weight: bold;
            font-size: 38px;
            line-height: 110%;
            text-align: center;
        }

        & .subtitle {
            max-width: 303px;

            color: @white;
            font-size: 14px;
            line-height: 140%;
            text-align: center;

            .responsive("ant-md", {
                max-width: 788px;

                font-size: 20px;
            });
        }

        & .collection-form {
            position: relative;

            width: 100%;
            max-width: 320px;
            margin-top: 32px;
            overflow: hidden;

            & .sign-up-success {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;

                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 48px;

                background-color: #b4d3ff;
                border-radius: 2px;
            }

            & .ant-form-item {
                margin-bottom: 0;
            }

            & .email-form-item {
                margin-bottom: 8px;

                & .ant-input-affix-wrapper {
                    height: 48px;
                }
            }

            & .submit-form-item {
                & button {
                    width: 100%;
                    height: 48px;
                }
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;