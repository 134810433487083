:root {
    --app-shop-usa-cta-height: 0;
}

body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media only screen and (min-width: 768px) and (max-width: 1025px) {
    #chat-widget-container {
        bottom: 60px !important;
    }
}
