@import "../../Global.less";

.ExpandableContainer {
    // Prevent bottom on the last item
    // This container holds the compact (initial) set of components
    > :first-child {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    > :nth-last-child(2) {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .header-text {
        color: @grey-2;

        .anticon-plus-circle {
            margin-right: 4px;

            color: @grey-2;
        }
    }

    // Prevent bottom on the last item
    // This container holds the expanded set of components
    .ant-collapse-content-box {
        padding: 0 !important;

        > :last-child {
            margin-bottom: 0 !important;
        }
    }

    .ant-collapse-item {
        display: flex;
        flex-direction: column-reverse;
    }

    .ant-collapse-header {
        justify-content: right !important;
        padding-top: 6px !important;
        padding-right: 4px !important;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;