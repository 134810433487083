@import "../../Global.less";

.TopicsV2 {
    .StoryHeader .container {
        padding-right: 20px;
        padding-left: 20px;
    }

    .topic-error {
        min-width: 75vw;
        height: auto;

        .responsive("ant-xs", {
            min-width: 65vw;
        });

        .responsive("ant-sm", {
            min-width: 50vw;
        });

        .responsive("ant-md", {
            min-width: 320px;
            max-width: 320px;
        });
    }

    .topic-summary {
        z-index: 2;

        margin-top: 50px;
        padding: 40px;

        background: rgba(25, 30, 37, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 8px;

        .title-container {
            display: flex;

            .title {
                margin-left: 10px;

                color: @white;

                font-weight: 700;
                font-size: 16px;
                line-height: 130%;
            }
        }

        .para {
            margin-top: 20px;

            color: @white;
            font-weight: 400;
            font-size: 16px;

            span {
                font-weight: 600;
                line-height: 150%;
            }
        }

        .summary-length {
            .max_lines(4);
        }

        .show-more {
            position: relative;
            left: -15px;

            & .ant-collapse-header {
                position: absolute;
                bottom: -15px;
                left: 0;

                color: white;
                text-decoration: underline;
            }
        }
    }

    .deep-dives {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 20px;

        background: #f8f9fb;

        /* stylelint-disable-next-line no-descending-specificity */
        .title {
            margin-left: 10px;

            color: #081a33;

            font-weight: 600;
            font-size: 18px;
            line-height: 140%;
        }

        .stories {
            display: flex;
            align-items: center;
            padding-top: 15px;

            .story {
                padding-right: 15px;

                color: #3b4355;

                font-weight: 400;
                font-size: 20px;
                line-height: 110%;
                text-decoration: underline;

                cursor: pointer;
            }

            .dot {
                width: 8px;
                height: 8px;
                margin-right: 15px;

                background: #e1e5eb;
                border-radius: 2px;
            }
        }
    }

    .by-numbers {
        margin-top: 10px;

        .responsive("ant-md",{
            margin-top: 70px;
        });

        /* stylelint-disable-next-line no-descending-specificity */
        .title,
        .percent,
        .opinion {
            color: @grey-1;

            font-weight: 700;
            font-size: 18px;
        }

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 25px;

            text-align: center;

            border: 1px solid #e1e5eb;
            border-radius: 4px;

            .percent {
                font-size: 40px;
            }

            .opinion {
                font-weight: 400;
                font-size: 20px;
            }
        }

        .top-stories {
            margin-top: 10px;

            .responsive("ant-md",{
                margin-top: 40px;
            });

            .title {
                color: @grey-1;

                font-weight: 700;
                font-size: 18px;
            }

            .topic {
                display: flex;
                align-items: center;
                height: 44px;
                padding: 8px;

                border-radius: 4px;
                cursor: pointer;

                &:not(:last-child) {
                    margin: 24px 0;
                    .responsive("ant-lg",{
                        margin: 35px 0;
                    });
                }
            }

            .circle {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 28px;
                min-width: 28px;
                height: 28px;
                min-height: 28px;
                margin: 0;

                color: @grey-1;
                font-weight: bold;
                font-size: 14px;

                background-color: @grey-6;
                border-radius: 50%;
            }

            .topic-name {
                display: inline-block;
                margin: 0 0 0 8px;

                font-weight: 600;
                font-size: 16px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .related {
        padding: 40px 0;

        background-color: @brand-navy;

        .max-width-related {
            margin: 0 auto;
            padding: 0 20px;

            .responsive("ant-lg",{
                max-width: 950px;
                padding: 0;
            });
            .responsive("ant-xl",{
                max-width: 1020px;
            });
        }

        .non-scroll {
            .responsive("ant-md",{
                margin-right: 0;
            });
        }

        .title-container {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            margin-bottom: 20px;

            .related-title {
                margin-bottom: 0;

                color: @white;
                font-weight: bold;
                font-size: 30px;
                line-height: 120%;
            }

            .view-all {
                color: @white;
                font-weight: normal;
                font-size: 16px;
                line-height: 28px;
                text-align: right;
                text-decoration: underline;
            }
        }

        .related-divider {
            margin: 60px 0;

            border-color: @grey-1;
        }

        .related-stories-container {
            display: flex;
            max-height: 410px;

            overflow-x: auto;

            & > * + * {
                margin-left: 20px;
            }

            .responsive("ant-lg", {
                & > * + * {
                    margin-left: 30px;
                }
            });

            .StoryPreviewCard {
                min-width: 75vw;
                height: auto;

                .responsive("ant-xs", {
                    min-width: 65vw;
                });

                .responsive("ant-sm", {
                    min-width: 50vw;
                });

                .responsive("ant-md", {
                    min-width: 320px;
                    max-width: 320px;
                });
            }
        }
    }

    .topic-highlights {
        max-width: 1200px;
        margin: auto;
        padding: 20px;

        .responsive("ant-md",{
            padding: 40px 80px;
        });

        .NewsSearchResult .title {
            font-size: 16px;
        }

        .highlights-title {
            .title-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                text-align: center;

                .responsive("ant-sm",{
                    flex-direction: row;
                    justify-content: space-between;
                    text-align: initial;
                });

                .title {
                    font-weight: 600;
                    font-size: 28px;
                }

                .search-topic {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 35px;
                    padding: 10px;

                    white-space: nowrap;

                    border: 1px solid @grey-5;

                    border-radius: 5px;

                    span {
                        padding-right: 5px;
                    }

                    .responsive("ant-sm",{
                        width: auto;
                    });
                }
            }

            .publisherIcon {
                margin-right: 10px;
            }

            .publisherDetails {
                margin-top: 20px;
                margin-bottom: 12px;
            }

            .description {
                color: @grey-1;

                font-size: 18px;
            }

            .desc-lines {
                .max_lines(3);
            }

            .show-description {
                margin-top: 30px;

                text-decoration: underline;

                cursor: pointer;
            }
        }

        .show-more {
            position: relative;
            left: -15px;

            & .ant-collapse-header {
                position: absolute;
                bottom: -15px;
                left: 0;

                text-decoration: underline;
            }
        }
    }

    .bias-container {
        display: flex;
        overflow-x: auto;

        .ContentCard,
        .TweetCard {
            flex: 1;
            min-width: 230px;
            height: auto;
            max-height: 310px;
            margin-right: 10px;

            .headline {
                .max_lines(2);
                font-size: 18px;
            }

            .tweet-body-text {
                font-size: 16px;
            }

            .responsive("ant-sm",{
                .headline {
                    .max_lines(3);
                }
            });

            .responsive("ant-lg",{
                min-width: 190px;
            });
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;