@import "../../Global.less";

.ContentFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 12px;

    font-size: 14px;

    span {
        line-height: 100%;
    }

    .bias {
        font-weight: bold;
    }

    .video {
        margin-left: 7px;

        color: @grey-2;

        font-size: 12px;
    }

    .right {
        margin-left: 5px;

        color: #c43351;
    }

    .generic {
        color: #3b4355;
    }

    .left {
        margin-left: 5px;

        color: #2168cc;
    }

    .date {
        margin-left: auto;

        color: @grey-2;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;