@import "../../Global.less";

.CategorySelect {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;

    .responsive("ant-md", {
        flex-direction: row;
        flex-wrap: wrap;
        margin: -4px;
    });
}

& .Pill {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    padding: 8px 16px;

    font-size: 16px;
    line-height: 140%;

    border: 1px solid @grey-5;
    border-radius: 999px;
    cursor: pointer;

    &:hover:not(.Pill-Active),
    &:focus:not(.Pill-Active) {
        background-color: @brand-navy-9;
    }

    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

& .Pill-Active {
    color: @white;

    background-color: @brand-navy;
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;