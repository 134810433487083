@import "../../Global.less";

h4.ant-typography.AnsoContainerTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;

    color: #fff;
    font-weight: bold;
    font-size: 24px;
    font-style: normal;
    line-height: 120%;

    a.ant-typography {
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-decoration-line: underline;
    }
}

div.ant-typography.AnsoParagraph {
    color: #9aa2b3;
    font-size: 16px;
    line-height: 140%;
}

.AnsoContainer {
    display: flex;
    flex-direction: column;
    padding: 16px;

    background-color: #081a33;
    border: 1px solid #fff;
    border-radius: 4px;

    @media only screen and (max-width: 992px) {
        margin: 0;
        padding: 0;

        background-color: #fff;
    }

    .anso-articles {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 100%;

        @media only screen and (max-width: 992px) {
            flex-direction: row;
            overflow-x: scroll;
        }

        :last-child {
            margin-right: 0;
        }
    }

    & .HorizontalItem {
        margin-right: 16px;

        background: #f8f9fb;
        border: 1px solid #e1e5eb;
        border-radius: 4px;

        & .ArticleContext {
            span {
                color: #7d8799;
                font-size: 14px;
                line-height: 140%;
            }

            span:nth-of-type(2) {
                display: none;
            }
        }

        & .HorizontalItemImg {
            width: 100px;
            height: 150px;
            margin-right: 0;
        }

        & .SpecialTag {
            color: #c43351;
            font-weight: bold;
            font-size: 14px;
            line-height: 130%;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            .max_lines(1);
        }

        & .item-details {
            width: 180px;
            padding: 12px;

            @media only screen and (min-width: 768px) and (max-width: 992px) {
                width: 100%;
                min-width: 180px;
            }
        }

        & h5.AntTitle.ant-typography.HorizontalItemTitle {
            color: #3b4355;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            .max_lines(4);
        }
    }

    & .AnsoImageArticle {
        margin-bottom: 24px;

        background-color: #fff;
        border-radius: 4px;

        .ImageArticle {
            width: 100%;

            .ImageLoader {
                background-color: transparent;
            }

            .ImageLoader .ant-image .ant-image-img {
                min-height: 165px;
                max-height: 165px;

                background-color: transparent;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;

                .ant-image-img-placeholder {
                    background-color: transparent;
                }
            }

            .ImageArticleTitle {
                padding: 0 16px;

                color: #3b4355;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                .max_lines(4);
            }

            .SpecialTitle {
                padding: 0 16px;

                color: #c43351;
                font-weight: bold;
                font-size: 14px;
                line-height: 130%;
                letter-spacing: 0.05em;
                text-transform: uppercase;
            }

            *:not(:last-child) {
                margin-bottom: 4px;
            }

            .ArticleContext {
                padding: 0 16px 16px;

                span:first-of-type,
                span:nth-of-type(2),
                svg {
                    display: none;
                }

                span:last-of-type {
                    color: #7d8799;
                    line-height: 140%;
                }
            }
        }
    }

    & .ant-row {
        margin-bottom: 16px;
    }

    & .AnsoImageArticle:last-of-type,
    & .ant-row:last-of-type {
        margin-bottom: 0;
    }

    & a.ant-typography.AnsoMobileLink {
        color: #3b4355;
        font-size: 14px;
        line-height: 140%;
        text-decoration-line: underline;
    }

    & .ant-divider-horizontal {
        margin-bottom: 0;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;