@import "../../Global.less";

& .ShopUsaMenu-mobile {
    position: relative;

    & .chevron {
        margin: -16px;
        padding: 16px;

        cursor: pointer;

        & svg {
            width: 16px;
            height: 16px;

            color: #081a33;
        }
    }

    & .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;

        border-top: 1px solid @grey-6;
        border-bottom: 1px solid @grey-6;

        &-text {
            color: @grey-1;
            font-weight: bold;
            font-size: 16px;
            line-height: 130%;
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }

        &-icon {
            margin: -16px;
            padding: 16px;

            cursor: pointer;

            & svg {
                width: 16px;
                height: 16px;

                color: @grey-1;
            }
        }
    }

    .ShopUsaBreadcrumbs {
        padding: 16px;
    }

    & .dropdown {
        position: absolute;
        z-index: 1;

        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-x: hidden;

        background-color: @white;
        transform-origin: top;

        & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;

            border-bottom: 1px solid #edf0f5;
            cursor: pointer;

            & .drawer-option {
                color: @grey-1;
                font-size: 16px;
                line-height: 140%;
            }

            & .chevron-right {
                margin: -16px;
                padding: 16px;

                cursor: pointer;

                & svg {
                    width: 16px;
                    height: 16px;

                    color: #081a33;
                }
            }
        }
    }

    & .drawer {
        position: absolute;
        z-index: 2;

        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 16px;
        overflow-x: hidden;
        overflow-y: scroll;

        background-color: @white;

        & .chevron {
            margin-right: auto;
            margin-bottom: 8px;
        }

        & .drawer-title {
            color: @grey-1;
            font-weight: bold;
            font-size: 24px;
            line-height: 120%;
        }

        & .subcategories {
            display: flex;
            flex-direction: column;

            & .subcategory {
                margin-top: 16px;

                color: @grey-1;
                font-size: 16px;
                line-height: 140%;
            }
        }

        & .featured-products {
            display: flex;
            flex-wrap: wrap;
            margin-right: -40px;

            & .FeaturedBrand {
                flex: 1 0 calc(50% - 40px);
                max-width: calc(50% - 40px);
                margin-top: 16px;
                margin-right: 40px;
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;