@import "../../Global.less";

.ShareExpanded {
    display: flex;

    & > * + * {
        margin-left: 8px;
    }

    & .bg-facebook {
        background-color: #415a94;
    }

    & .bg-twitter {
        background-color: #6babe9;
    }

    & .bg-email {
        background-color: @brand-red;
    }

    & .bg-link {
        background-color: @brand-blue;
    }

    & .bg-more {
        background-color: #3b4355;
    }
}

.ShareIcon {
    position: relative;

    width: 40px;
    height: 40px;

    border-radius: 50px;
    cursor: pointer;

    &:hover {
        filter: brightness(90%);
    }

    &:active {
        filter: brightness(80%);
    }

    & svg {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 16px;
        height: 16px;

        color: #fff;

        transform: translate(-50%, -50%);
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;