@import "../../Global.less";

.FeaturedBrand {
    display: flex;

    cursor: pointer;

    &--vertical {
        flex-direction: column;

        & .cover {
            height: 164px;
            margin-bottom: 12px;
        }

        & .info {
            & .title {
                font-size: 16px;
                line-height: 130%;
                .max_lines(1);
            }

            & .description {
                .max_lines(4);
            }
        }
    }

    &--horizontal {
        flex-direction: row;
        align-items: center;
        max-width: 393px;
        padding: 12px;

        border-radius: 8px;

        & .cover {
            min-width: 80px;
            max-width: 80px;
            height: 80px;
            margin-right: 16px;
        }

        & .info {
            & .title {
                margin-bottom: 4px;

                font-size: 20px;
                line-height: 120%;

                .max_lines(2);
            }

            & .description {
                .max_lines(2);
            }
        }

        &:hover {
            background-color: @grey-6;
        }
    }

    & .cover {
        overflow: hidden;

        border-radius: 2px;

        & .image {
            .cover_img();
        }
    }

    & .info {
        display: flex;
        flex-direction: column;

        & .title {
            color: @grey-1;
            font-weight: bold;
        }

        & .description {
            color: @grey-2;
            font-size: 16px;
            line-height: 140%;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;