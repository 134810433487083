@import "../../Global.less";

.TopicsSidebar {
    width: 100%;

    background-color: #f8f9fb !important;

    & .topics-mobile {
        display: flex;
    }

    &.ant-card {
        border-radius: 0;
    }

    & .ant-card-head,
    & .ant-card-body {
        border-color: #e1e5eb;
    }

    & .ant-card-head {
        padding-right: @offset-xs !important;
        padding-left: @offset-xs !important;

        background-color: #edf0f5;

        & .ant-card-head-wrapper {
            height: 48px;
        }

        & .ant-card-head-title {
            color: #3b4355;
            font-weight: bold;
        }

        & .ant-card-extra a {
            color: #3b4355;
            text-decoration: underline;
        }
    }

    & .ant-divider {
        margin: 0;
    }

    .responsive("ant-lg", {
        &.ant-card {
            border-radius: @border-radius;
        }
    });

    .ant-card-body {
        padding: 0 !important;

        .responsive("ant-lg", {
            padding: 0 12px !important;
        });
    }

    /* Spacer to account for Safari mobile */
    .topics-item-spacer {
        padding-right: 8px;

        border: 1px solid transparent;

        &::after {
            content: "";
        }

        .responsive("ant-lg", {
            display: none;
        });
    }

    .topics {
        padding: @offset-xs 0 @offset-xs @offset-xs !important;

        .responsive("ant-lg", {
            padding: 0 !important;
        });
    }

    @media screen and (max-width: 1025px) {
        .ant-card-body {
            padding-right: 0 !important;
        }

        .topics {
            display: flex;
            overflow-x: auto;
        }

        & .ant-divider {
            display: none;
        }

        & .TopicsSidebarItem {
            align-items: center;
            box-sizing: border-box;
            height: 48px;
            padding: 8px;

            background: #fff;
            border: 1px solid #e1e5eb;
            border-radius: 5px;

            &:not(:last-of-type) {
                margin-right: 8px;
            }

            &:last-of-type {
                margin-right: 0;
            }

            & .title {
                & div:first-of-type {
                    white-space: nowrap;
                }
            }

            ::after {
                content: "";
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;