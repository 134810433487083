@import "../../Global.less";

.ContentCard {
    position: relative;

    display: flex;
    flex-direction: column;
    min-width: 255px;
    height: 100%;

    background: #fff;
    border: @border-gray-1;
    border-radius: @border-radius;

    .image {
        position: relative;

        width: auto;

        min-height: 150px;
        max-height: 150px;

        img {
            height: 100%;

            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }

        & img:-moz-loading,
        & img:-moz-broken {
            color: transparent;

            border: transparent;
        }
    }

    .play-background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .content {
        position: relative;

        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        padding: 12px 12px 0;
    }

    .publisher {
        display: flex;
        width: 100%;
    }

    .publisher-logo {
        margin-right: 8px;
    }

    .publisher-name {
        color: @grey-1;
        font-size: 14px;
        .max_lines(1);
    }

    .title {
        .max_lines(3);

        margin: 7px 0 10px;

        color: @grey-1;
        font-size: 16px;
        line-height: 130%;
    }

    .divider.ant-divider-horizontal {
        min-width: 100%;
        margin: 0 auto;

        border-color: @grey-6;
    }

    .bold {
        font-weight: bold;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;