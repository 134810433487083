@import "../../Global.less";

.SimilarityContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 15px;
    overflow: hidden;

    background-color: @white;
    border: 1px solid @grey-5;
    border-radius: 4px;

    .SimilarityCard {
        height: 100%;

        overflow: hidden;

        .list {
            .ant-list-header {
                padding: 12px 18px;

                .header-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    color: @white;

                    .title {
                        margin: 0;

                        color: @white;
                        font-weight: bold;
                        font-size: 14px;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                    }
                }
            }

            .ant-list-items {
                background-color: @white;

                .item {
                    &::before {
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        margin-right: 14px;
                        margin-bottom: 2px;

                        border-radius: 50%;
                        opacity: 0.5;

                        content: "";
                    }

                    flex-wrap: nowrap;
                    align-items: baseline;
                    justify-content: flex-start;
                    margin: 12px 16px;
                    padding: 0;

                    color: @grey-1;
                    font-size: 16px;

                    border-bottom: none !important;
                }

                .source-link {
                    display: inline-block;
                    margin-left: auto;

                    color: #002868;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .source-name {
                    font-weight: 600;
                    font-size: 16px;
                    font-style: italic;
                }
            }

            &.right {
                .ant-list-header,
                & .ant-list-items .item::before {
                    background-color: @brand-red;
                }
            }

            &.left {
                .ant-list-header,
                & .ant-list-items .item::before {
                    background-color: @brand-blue;
                }
            }

            &.middle {
                .ant-list-header,
                & .ant-list-items .item::before {
                    background-color: @grey-1;
                }
            }
        }
    }

    .show-btn {
        &:hover,
        &:focus {
            color: @grey-1;
        }

        span {
            color: @grey-1;
            font-size: 16px;
            text-decoration: underline;
        }

        &.show-less-btn {
            span {
                text-decoration: none;
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;