.ArticleContext {
    display: flex;
    align-items: center;

    & > span {
        float: right;

        margin-right: 8px;
        padding-right: 8px;

        white-space: nowrap;

        border-right: 1px solid #bfbfbf;

        &:first-of-type {
            display: block;
            float: none;
            overflow: hidden;

            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &:last-of-type {
            margin-right: 0;
            margin-left: 0;
            padding-right: 0;

            border-right: none;
        }
    }

    & svg {
        width: 13px;
        height: 13px;
        margin-right: 4px;
    }

    & .ant-image {
        margin-right: 8px;

        @media only screen and (max-width: 640px) {
            margin-right: 4px;
        }

        & > img {
            width: 24px;
            height: 24px !important;

            object-fit: contain !important;
            object-position: center !important;

            border-radius: 50%;

            @media only screen and (max-width: 640px) {
                width: 13px;
                height: 13px !important;
            }
        }
    }

    & .ant-image:not(.ant-image-error) {
        & .ant-image-img-placeholder {
            background-color: transparent;
            background-image: none;
        }
    }

    & .ant-typography {
        color: #595959;
        font-weight: normal;
        font-size: 14px;
        font-family: "Source Sans Pro";
        font-style: normal;
        line-height: 1.3;

        @media only screen and (max-width: 640px) {
            font-size: 10px;
        }
    }

    & .ImageLoader {
        position: inherit;

        display: flex;

        overflow: visible;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;