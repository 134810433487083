@import "../../Global.less";

.ImagesSearch {
    // position: relative;

    flex: 1 1;
    width: 100%;
    margin: 16px auto;
    padding: 0 10px;

    .responsive("ant-md", {
        padding: 0 20px;
    });

    .responsive("ant-xl", {
        padding: 0 32px;
    });

    .d-none {
        display: none;
    }

    .images-container {
        display: flex;
        flex: 1;
        align-items: flex-start;
        height: 100%;
        margin: 0 auto 32px;
        overflow: hidden;

        .row {
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            max-height: 228px;
            overflow: hidden;
        }

        .column {
            padding: 0 5px;
            overflow: hidden;

            & > * {
                margin-bottom: 10px;
            }

            .filler {
                height: 100%;

                background-color: rgba(black, 0.04);
                border-radius: 4px;
            }
        }
    }

    .image-pagination {
        margin-bottom: 40px;
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;