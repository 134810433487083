@import "../../Global.less";

.BusinessPlace {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    border: 1px solid @grey-5;
    border-radius: 4px;

    .responsive("ant-sm", {
        flex-direction: row;
    });

    .business-image-link {
        display: flex;
        width: auto;
        height: auto;
        min-height: 160px;
        max-height: 160px;
        overflow: hidden;

        .responsive("ant-sm", {
            min-width: 33%;
            max-width: 33%;
            min-height: 160px;
            height: 100%;
            max-height: 160px;
        });
    }

    .business-image {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center center;

        .responsive("ant-sm", {
            border-right: 1px solid @grey-5;
        });
    }

    .business-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 12px;
    }

    .business-title {
        margin-bottom: 0;

        color: @grey-1;
        font-weight: 600;

        &:hover {
            text-decoration: underline;
        }
    }

    .business-price {
        color: @grey-1;

        font-weight: 600;
        font-size: 14px;
    }

    .business-rating-link {
        display: flex;
        align-items: center;
    }

    .business-rating-image {
        width: auto;
        max-height: 20px;
        object-fit: cover;
    }

    .business-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 6px;

        .responsive("ant-sm", {
            margin-top: auto;

        });
    }

    .business-yelp-logo {
        width: auto;
        height: 20px;
    }

    .business-rating-text {
        margin-left: 10px;

        color: @grey-2;

        &:hover {
            text-decoration: underline;
        }
    }

    .business-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .business-info-item {
            display: flex;
            align-items: center;
            margin: 4px 0;

            svg {
                margin-right: 12px;
            }

            .business-info-item-text {
                margin: 0;

                color: @grey-1;
                font-weight: 400;
                font-size: 14px;
                line-height: 100%;
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;