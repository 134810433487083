.ImageArticle {
    display: flex;
    flex-direction: column;
    width: 136px;
    min-width: 136px;

    & > *:not(:last-child) {
        margin-bottom: 8px;
    }

    & .ant-tag-red {
        align-self: flex-start;

        background: #fff;
        border: 1px solid #bf0a30;
    }

    & > .ImageLoader {
        & img {
            border-radius: 2px;
        }
    }
}

.ImageArticleTitle {
    display: -webkit-box;
    overflow: hidden;

    font-weight: bold;
    font-size: 12px;
    font-family: "Source Sans Pro";
    font-style: normal;
    line-height: 130%;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;