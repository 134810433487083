@import "../../Global.less";

.TopicSearch {
    flex: 1 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px 16px;
    overflow: visible;

    @media only screen and (min-width: 641px) and (max-width: 1260px) {
        padding: 0 24px;
    }

    @media only screen and (max-width: 640px) {
        flex-direction: column;
        min-height: 100vh;
        margin: 0;
        padding: 0 16px;
    }

    @media only screen and (min-width: 1250px) {
        padding: 24px 0;
    }

    .top-results {
        color: @brand-navy;
        font-weight: 700;
        font-size: 24px;
    }

    .TopicSearchTitle {
        margin: 40px 0 32px;

        .responsive("mobile", {
            margin: 16px 0 8px 0;
        });

        span.ant-typography {
            display: block;

            color: #7d8799;
            line-height: 140%;
        }

        h3.ant-typography {
            margin: 4px 0 0;

            font-weight: bold;
            line-height: 120%;
        }
    }

    .ant-breadcrumb {
        margin: 80px 16px 40px 0;

        @media only screen and (min-width: 641px) and (max-width: 1260px) {
            margin: 40px 16px 24px 0;
        }

        @media only screen and (max-width: 640px) {
            margin: 16px 16px 8px 0;
        }
    }

    .SearchMoreButton {
        height: 40px;
        margin-bottom: 24px;
        padding: 0;

        .responsive("mobile", {
            margin-top: 16px;
            height: 32px;
        });

        .search-more-btn {
            height: 100%;
            padding: 0;

            color: #3b4355;
            font-size: 16px;

            border: 1px solid #e1e5eb;
            border-radius: 4px;

            transition: border 300ms ease-out;

            .responsive("mobile", {
                font-size: 14px;
            });

            &:hover,
            &:focus {
                color: @brand-blue;

                border: 1px solid @brand-blue;
            }

            & a {
                border: none;
            }

            & .anticon {
                margin-right: 8px;
            }
        }
    }

    .Tweet {
        min-width: 255px;
        height: 100%;

        .responsive("ant-sm", {
            min-width: 230px;
        });

        .TweetBody > a {
            font-size: 16px;
        }
    }

    .TopicSearchSpin {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 32px auto;
        padding: 16px;
    }

    .topic-search-main > * {
        margin-bottom: 32px;

        @media only screen and (max-width: 640px) {
            margin-bottom: 16px;
        }
    }

    .topic-search-col {
        display: flex;
        flex-direction: column;
        height: fit-content;

        & .InvolvementCard {
            max-width: none;
        }

        & > * {
            margin-bottom: 24px;
        }

        @media only screen and (max-width: 640px) {
            display: none;
        }
    }

    & .RelatedResults {
        padding-top: 24px;
        padding-bottom: 24px;

        border-top: 1px solid #e1e5eb;
        border-bottom: 1px solid #e1e5eb;

        .responsive("ant-md", {
            padding-top: 40px;
            padding-bottom: 40px;
        });
    }

    & .SpangldSection {
        width: 100%;

        background: #f8f9fb;
        border: 1px solid #e1e5eb;
        border-radius: @border-radius;

        .SpangldTake {
            display: flex;
            height: auto;
            padding: 0 24px;

            border-right: 1px solid #e1e5eb;

            @media only screen and (max-width: 640px) {
                width: 100%;
                padding: 8px 12px;

                border-right: 0;
                border-bottom: 1px solid #e1e5eb;
            }

            .spangld-take-header {
                padding: 0;

                border: none;
            }
        }

        .SummaryWrap {
            padding: 16px;
        }
    }

    .app-banner {
        padding-top: 0;
        padding-right: 0;
        padding-left: 0;

        border: none;

        .responsive("ant-lg", {
            padding-top: 20px;
            padding-bottom: 20px;
            margin-bottom: 40px;
            border-top: 1px solid @grey-5;
            border-bottom: 1px solid @grey-5;

            .text-block {
                min-width: unset;
                background: none;
            }
        });
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;