@import "../../Global.less";

@keyframes metadata-in-out {
    0% {
        height: 0;

        opacity: 0;
    }

    100% {
        height: 100px;

        opacity: 1;
    }
}

@keyframes image-zoom {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.025);
    }
}

.ImageSearchResult {
    width: 100%;
    height: auto;
    overflow: hidden;

    border: 1px solid @grey-5;
    border-radius: 4px;

    .image-container {
        width: 100%;

        cursor: pointer;

        .image-button {
            width: 100%;
            height: 100%;
            padding: 0;

            border: none;
            cursor: pointer;
        }

        .image {
            width: 100%;
            height: auto;

            object-fit: contain;
        }
    }

    .metadata-container {
        .name {
            margin: 0 0 4px;

            color: @grey-1;
            font-weight: 400;
            font-size: 14px;

            cursor: pointer;

            .max_lines(2);

            &:hover {
                text-decoration: underline;
            }
        }

        .meta {
            color: @grey-1;
            font-weight: 400;
            font-size: 12px;
        }

        .host {
            .ellipses();

            &:hover {
                text-decoration: underline;
            }
        }

        .bullet-point {
            margin: 0 6px;

            color: @grey-3;
        }

        .dimensions {
            display: inline-block;
        }
    }
}

.ImageSearchResult.with-metadata {
    .metadata-container {
        padding: 5px 10px 10px;
    }
}

.ImageSearchResult.with-metadata-on-hover {
    position: relative;

    .image-container {
        z-index: 1;
    }

    .metadata-container {
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        z-index: 2;

        display: flex;

        width: 100%;
        height: auto;

        visibility: hidden;

        .metadata-wrapper {
            width: 100%;
            padding: 8px;

            background-color: white;
            opacity: 0.95;
        }

        .name {
            margin-top: 0;

            cursor: pointer;
        }
    }

    &:hover {
        .metadata-container {
            visibility: visible;

            animation: metadata-in-out 0.25s;
            animation-fill-mode: forwards;
        }

        .image-container {
            animation: image-zoom 0.3s;
            animation-fill-mode: forwards;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;