@import "../../Global.less";

.Timeline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ant-timeline.ant-timeline-label .ant-timeline-item-label {
        width: 30px;
    }

    .timeline-item:not(:last-child) {
        padding-bottom: 52px;
    }

    .timeline-item:last-child {
        padding-bottom: 0;
    }

    .ant-timeline-item-tail,
    .ant-timeline-item-head {
        left: 46px !important;
    }

    .ant-timeline-item-tail {
        border-left-color: @grey-5;
    }

    .ant-timeline-item-content {
        left: 40px !important;

        width: calc(100% - 66px) !important;
    }

    .item-date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .month {
            color: @brand-red;
            font-weight: bold;
            font-size: 19px;
            line-height: 50%;
            text-align: center;
        }

        .day {
            color: @grey-1;
            font-weight: bold;
            font-size: 30px;
            line-height: 120%;
        }
    }

    .item-link {
        color: @grey-1;
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;

        &:hover {
            color: @grey-1 !important;
            text-decoration: underline !important;
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;