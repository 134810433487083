@import "@/components/Global.less";

.ProductListing {
    position: relative;

    display: block;

    &--fullWidth {
        width: 100% !important;
    }

    &--default {
        width: 376px;
    }

    &--small {
        width: 164px;
    }

    & .cover {
        width: 100%;
        overflow: hidden;

        background: @white;
        border-radius: 4px;

        &--default {
            height: 376px;
            margin-bottom: 16px;
        }

        &--small {
            height: 164px;
            margin-bottom: 8px;
        }

        & .image {
            .cover_img();
            .img_hover_overlay_with_scaling(1);
        }
    }

    & .body {
        display: flex;

        &--default {
            flex-direction: row;
            justify-content: space-between;
        }

        &--small {
            flex-direction: column;
        }

        & .col {
            display: flex;
            flex-direction: column;
        }

        & .title {
            margin-right: 8px;

            color: #3b4355;
            line-height: 140%;

            &--default {
                font-size: 20px;
            }

            &--small {
                font-size: 14px;
            }

            &:hover {
                text-decoration: underline;
            }

            .max_lines(2);
        }

        & .price {
            color: @grey-1;
            font-weight: bold;
            line-height: 140%;
            white-space: nowrap;

            &--default {
                font-size: 20px;
            }

            &--small {
                font-size: 16px;
            }
        }

        & .brand {
            color: @grey-2;
            font-size: 14px;
            line-height: 140%;

            .max_lines(1);
        }
    }

    & .new-arrival {
        position: absolute;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: @white;
        border: 1px solid @grey-5;
        border-radius: 2px;

        & .ant-typography {
            color: #000;
            line-height: 100%;
        }

        &--default {
            top: 16px;
            left: 16px;

            padding: 12px 16px;

            & .ant-typography {
                font-size: 16px;
            }
        }

        &--small {
            top: 8px;
            left: 8px;

            padding: 4px 8px;

            & .ant-typography {
                font-size: 10px;
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;