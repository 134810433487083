@import "../../Global.less";

.FreespokeLoader {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .loading-container {
        .loading-img {
            width: 300px;
            height: 300px;
        }

        .loading-text {
            margin-top: -30px;

            color: @grey-1;
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
            text-decoration-line: underline;
        }
    }

    .fallback-container {
        width: 100%;

        .fallback-header {
            padding: 82px 0 60px;

            border-bottom: @border-gray-1;

            .inner-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width: 562px;
                margin: 0 auto;
                padding: 0 20px;
            }

            .fallback-title {
                color: @grey-1;
                font-weight: 700;
                font-size: 32px;
                line-height: 110%;
                text-align: center;
                text-transform: uppercase;
            }

            .fallback-text {
                margin-bottom: 40px;

                color: @grey-2;
                font-weight: 400;
                font-size: 20px;
                line-height: 140%;
                text-align: center;

                &.mb-0 {
                    margin-bottom: 0;
                }
            }

            .reload-link {
                width: 157px;
                height: 58px;
                margin-bottom: 30px;
                padding: 20px 30px;

                color: @white;
                font-weight: 700;
                font-size: 18px;
                line-height: 18px;

                background: @brand-red;
                border-radius: 6px;
            }
        }

        .fallback-body {
            max-width: 800px;
            margin: 0 auto;

            .fallback-about-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 60px 0;

                border-bottom: @border-gray-1;

                .about-torch-icon {
                    width: 44px;
                    height: 44px;
                    margin-bottom: 20px;
                }

                .about-text {
                    margin-bottom: 20px;

                    color: @grey-1;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 140%;
                    text-align: center;
                }

                .about-link {
                    color: @grey-1;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 140%;
                    text-align: center;
                    text-decoration-line: underline;
                }
            }

            .fallback-news-container {
                padding-top: 50px;

                .fallback-news-title {
                    display: flex;
                    margin-bottom: 40px;

                    color: @grey-1;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 120%;
                    text-align: center;

                    .responsive("ant-lg", {
                        text-align: unset
                    });
                }

                .NewsSearchResult {
                    margin-bottom: 60px;
                }
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;