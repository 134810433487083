@import "../../Global.less";

.WebSearch {
    flex: 1 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 32px;
    padding: 0 24px;
    overflow: auto;

    @media only screen and (min-width: 641px) and (max-width: 1260px) {
        margin-bottom: 0;
    }

    @media only screen and (max-width: 640px) {
        flex-direction: column;
        min-height: 100vh;
        margin: 0;
        padding: 16px;
    }

    .responsive("ant-xl", {
        padding: 0 32px;
    });

    & .component-col {
        width: 100%;
    }

    & .component-headline {
        margin-bottom: 20px;

        color: #000;
        font-weight: 600;
        line-height: 100%;
    }

    & .link-to-news {
        float: right;

        color: @brand-blue;
        font-size: 16px;

        &:hover {
            text-decoration: underline;
        }

        svg {
            path {
                fill: @grey-1;
            }
        }
    }

    .business-places {
        width: 100%;
    }

    .business-place {
        margin: 12px 0;
    }

    & .result-title {
        margin: 0;

        color: @grey-2;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
    }

    .InvolvementCard {
        max-width: none;
        margin-bottom: 16px;

        & .card-content {
            padding: 24px 16px !important;
        }
    }

    .WebPagination {
        margin: 12px 0 32px;
    }

    & .search-provider-credit {
        margin-bottom: 16px;
        padding-top: 8px;

        border-top: 1px solid #e1e5eb;

        & span,
        & a {
            color: #9aa2b3;
            font-size: 12px;
            line-height: 140%;
        }

        & a {
            margin-left: 4px;

            text-decoration: underline;

            &:hover {
                color: #9aa2b3;
                text-decoration: underline;
            }
        }
    }

    .app-banner {
        padding-top: 4px;
        padding-right: 0;
        padding-bottom: 24px;
        padding-left: 0;

        border: none;

        .responsive("ant-xl", {
            padding: 38px 20px;
        });
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;