@import "../../Global.less";

a.SpangldTake {
    display: block;
    width: fit-content;
    height: fit-content;

    background: #f8f9fb;

    .spangld-take-header {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: 6px 8px;

        font-weight: 700;
        font-size: 14px;
        font-family: "Source Sans Pro";
        line-height: 1.4em;
        letter-spacing: 0;
        text-transform: uppercase;

        border: 1px solid #d9d9d9;
        border-radius: @border-radius;
        border-radius: 2px;

        @media only screen and (max-width: 640px) {
            font-size: 12px;
        }

        & > img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            object-fit: cover;

            @media only screen and (max-width: 640px) {
                width: 16px;
                height: 16px;
            }
        }
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;