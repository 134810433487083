@import "../../Global.less";

.TopicPreviewCard {
    .card-container {
        width: 100%;
        height: 100%;

        background-color: rgba(255, 255, 255, 0.1);

        border-color: transparent;

        border-radius: 4px;
        box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.06);

        &:hover {
            background-color: rgba(255, 255, 255, 0.15) !important;

            transition: background-color 0.3s linear;
        }

        & span:last-child {
            margin-bottom: 0;
        }
    }

    .ant-card-body {
        padding: 15px;
    }

    .titleContainer {
        display: flex;
        align-items: center;

        color: @white;

        .title {
            margin: 0 15px 0 0;

            color: @white;

            font-weight: 600;
            line-height: 100%;
            .max_lines(2);
        }

        .image {
            margin-right: 15px;

            border-radius: 50%;
        }

        .right-arrow {
            margin-left: auto;

            > svg {
                width: 20px;
                height: 25px;
            }
        }
    }

    .divider {
        margin: 12px 0;

        border-top: 1px solid rgba(255, 255, 255, 0.2);
    }

    .text {
        margin: 10px 0;

        color: @grey-5;

        font-weight: 400;
        font-size: 16px;
        line-height: 1.1;
        .max_lines(1);
    }
}

@blue-base: #002868;@red-base: #C53351;@layout-body-background: #FFFFFF;@layout-header-background: #2D2D2D;@font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@typography-title-font-weight: normal;@btn-primary-bg: @red-base;@border-radius-base: 5px;